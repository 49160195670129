<template>
  <div class="how-content-item" id="benefits">
    <h2 class="how-content-title">{{ $t("investing-text-9") }}</h2>
    <div class="row gy-base">
      <div class="col-xl-6">
        <div class="box how-ben">
          <div class="box-inner how-ben-inner">
            <div class="how-ben-header d-flex align-items-center">
              <div
                class="how-ben-icon d-flex-center"
                style="background-color: #181625">
                <svg
                  width="29"
                  height="22"
                  viewBox="0 0 29 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.75 7.16658V19.9166L12.3828 20.4609C14.1163 21.0387 15.9315 21.3333 17.7587 21.3333H22.8492C24.8751 21.3333 26.6193 19.9033 27.0166 17.9167L28.078 12.6099C28.5383 10.3083 27.0457 8.06929 24.744 7.60896C24.3768 7.53552 24.0015 7.51093 23.6278 7.53584L18.5417 7.87492L19.0576 2.19952C19.1546 1.13217 18.368 0.188254 17.3007 0.0912217C17.2423 0.0859114 17.1837 0.083252 17.125 0.083252C15.8067 0.083252 14.585 0.774954 13.9067 1.90542L10.75 7.16658Z"
                    fill="#8950FC" />
                  <path
                    opacity="0.3"
                    d="M6.50065 5.75H2.25065C1.46825 5.75 0.833984 6.38426 0.833984 7.16667V19.9167C0.833984 20.6991 1.46825 21.3333 2.25065 21.3333H6.50065C7.28305 21.3333 7.91732 20.6991 7.91732 19.9167V7.16667C7.91732 6.38426 7.28305 5.75 6.50065 5.75Z"
                    fill="#8950FC" />
                </svg>
              </div>
              <h4 class="how-ben-text flex-fill m-0">
                {{ $t("investing-text-10") }}
              </h4>
            </div>
            <div class="how-ben-content">
              {{ $t("investing-text-11") }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="box how-ben">
          <div class="box-inner how-ben-inner">
            <div class="how-ben-header d-flex align-items-center">
              <div
                class="how-ben-icon d-flex-center"
                style="background-color: #221619">
                <svg
                  width="18"
                  height="27"
                  viewBox="0 0 18 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.8333 5.91658C11.3611 10.6388 9.94444 13.1654 7.58333 13.4962C7.58333 13.4962 9.70833 5.20825 6.875 0.958252C6.875 0.958252 6.57455 5.51205 3.53873 10.9765C2.11569 13.538 0.5 15.2647 0.5 18.6666C0.5 23.5239 5.47454 26.0342 9.0081 26.0342C12.5417 26.0342 17.5 24.3341 17.5 18.3839C17.5 15.9372 15.6111 11.7815 11.8333 5.91658Z"
                    fill="#D53A3A" />
                </svg>
              </div>
              <h4 class="how-ben-text flex-fill m-0">
                {{ $t("investing-text-12") }}
              </h4>
            </div>
            <div class="how-ben-content">
              {{ $t("investing-text-13") }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="box how-ben">
          <div class="box-inner how-ben-inner">
            <div class="how-ben-header d-flex align-items-center">
              <div
                class="how-ben-icon d-flex-center"
                style="background-color: #161925">
                <svg
                  width="28"
                  height="21"
                  viewBox="0 0 28 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.9998 5.66658H8.66644C5.72092 5.66658 3.33311 8.0544 3.33311 10.9999V12.3333C3.33311 14.5424 5.12397 16.3333 7.33311 16.3333V18.9999C3.65121 18.9999 0.666443 16.0151 0.666443 12.3333V10.9999C0.666443 6.58164 4.24816 2.99992 8.66644 2.99992H13.9998V1.30391C13.9998 1.16012 14.0463 1.02018 14.1323 0.904969C14.3526 0.609981 14.7704 0.54946 15.0654 0.769791L18.8748 3.61507C18.9266 3.65381 18.9726 3.69989 19.0111 3.75186C19.2306 4.04751 19.1688 4.46508 18.8732 4.68452L15.0638 7.51197C14.9489 7.59726 14.8096 7.64332 14.6664 7.64332C14.2983 7.64332 13.9998 7.34484 13.9998 6.97665V5.66658Z"
                    fill="#508AFC" />
                  <path
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.0786 16.3333H19.3342C22.2797 16.3333 24.6675 13.9455 24.6675 11V9.66667C24.6675 7.45753 22.8767 5.66667 20.6675 5.66667V3C24.3494 3 27.3342 5.98477 27.3342 9.66667V11C27.3342 15.4183 23.7525 19 19.3342 19H14.0786V20.3101C14.0786 20.6783 13.7801 20.9767 13.4119 20.9767C13.2688 20.9767 13.1295 20.9307 13.0146 20.8454L9.20523 18.0179C8.90958 17.7985 8.8478 17.3809 9.06724 17.0853C9.10582 17.0333 9.15176 16.9872 9.20362 16.9485L13.013 14.1032C13.308 13.8829 13.7257 13.9434 13.9461 14.2384C14.0321 14.3536 14.0786 14.4935 14.0786 14.6373V16.3333Z"
                    fill="#508AFC" />
                </svg>
              </div>
              <h4 class="how-ben-text flex-fill m-0">
                {{ $t("investing-text-14") }}
              </h4>
            </div>
            <div class="how-ben-content">
              {{ $t("investing-text-15") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InvestingBenefits",
};
</script>
