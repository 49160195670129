<template>
  <div class="sh sh-dashed sh-plus community">
    <div class="container">
      <div class="sh-header text-sm-center">
        <div class="sh-tag">
          <div class="sh-tag-text">
            <div class="sh-tag-shadow">{{ $t("home-text-7") }}</div>
          </div>
        </div>
        <h2 class="sh-title">
          {{ $t("home-text-8") }}
        </h2>
      </div>
      <div class="row gy-base community-row">
        <div class="col-lg-4">
          <div class="box h-100 community-box text-center">
            <div class="box-inner community-box-inner pt-0">
              <div
                class="community-box-shadow"
                style="--shadow-color: var(--s-darkviolet-color)"></div>
              <div class="community-box-img d-flex-center">
                <img src="@/assets/images/community-img-1.png" alt="coinfuze" />
              </div>
              <div class="community-box-content">
                <h4 class="community-box-title">{{ $t("home-text-9") }}</h4>
                <div class="community-box-desc">
                  {{ $t("home-text-10") }}
                </div>
              </div>
              <div class="community-box-footer">
                <a
                  class="bt bt-brd bt-brd-v"
                  rel="noopener noreferrer nofollow"
                  href="https://discord.gg/B6MCMDR9qP"
                  target="_blank">
                  {{ $t("home-text-11") }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box h-100 community-box text-center">
            <div class="box-inner community-box-inner pt-0">
              <div
                class="community-box-shadow"
                style="--shadow-color: var(--s-red-color)"></div>
              <div class="community-box-img d-flex-center">
                <img src="@/assets/images/community-img-2.png" alt="coinfuze" />
              </div>
              <div class="community-box-content">
                <h4 class="community-box-title">{{ $t("home-text-12") }}</h4>
                <div class="community-box-desc">
                  {{ $t("home-text-13") }}
                </div>
              </div>
              <div class="community-box-footer">
                <a
                  class="bt bt-brd bt-brd-r"
                  rel="noopener noreferrer nofollow"
                  href="https://discord.gg/B6MCMDR9qP"
                  target="_blank">
                  {{ $t("home-text-14") }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box h-100 community-box text-center">
            <div class="box-inner community-box-inner pt-0">
              <div
                class="community-box-shadow"
                style="--shadow-color: var(--s-darkblue-color)"></div>
              <div class="community-box-img d-flex-center">
                <img src="@/assets/images/community-img-3.png" alt="coinfuze" />
              </div>
              <div class="community-box-content">
                <h4 class="community-box-title">{{ $t("home-text-15") }}</h4>
                <div class="community-box-desc">
                  {{ $t("home-text-16") }}
                </div>
              </div>
              <div class="community-box-footer">
                <a
                  class="bt bt-brd bt-brd-b"
                  :href="`https://faq.coinfuze.com/${locale}/general-issues/how-do-i-get-started-with`"
                  target="_blank">
                  {{ $t("home-text-17") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeCommunity",
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },
};
</script>
