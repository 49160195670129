<template>
  <div class="sh sh-plus sh-dashed about">
    <div class="container">
      <div class="row gy-base">
        <div class="col-lg-6">
          <div class="about-inner">
            <div class="about-header">
              <div class="sh-header">
                <div class="sh-tag">
                  <div class="sh-tag-text">
                    <div class="sh-tag-shadow">
                      {{ $t("affiliate-text-22") }}
                    </div>
                  </div>
                </div>
                <h2 class="sh-title">
                  {{ $t("affiliate-text-23") }}<br />{{
                    $t("affiliate-text-24")
                  }}
                </h2>
              </div>
            </div>
            <div class="about-img text-center text-sm-end">
              <img src="@/assets/images/about-img-2.png" alt="coinfuze" />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content">
            <h4 class="about-content-title">
              {{ $t("affiliate-text-25") }}
            </h4>
            <p class="about-content-desc">
              {{ $t("affiliate-text-26") }}
            </p>
            <h4 class="about-content-title">
              {{ $t("affiliate-text-27-1") }}
            </h4>
            <p class="about-content-desc">
              {{ $t("affiliate-text-27") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AffiliateFundamentals",
};
</script>
