<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8758 5.77438C19.435 5.10271 17.875 4.61521 16.25 4.33355C16.2357 4.33309 16.2215 4.33576 16.2084 4.34138C16.1953 4.34699 16.1836 4.35541 16.1741 4.36605C15.9791 4.72355 15.7516 5.18938 15.6 5.54688C13.8764 5.28688 12.1235 5.28688 10.4 5.54688C10.2483 5.17855 10.0208 4.72355 9.81496 4.36605C9.80413 4.34438 9.77163 4.33355 9.73913 4.33355C8.11413 4.61521 6.56496 5.10271 5.1133 5.77438C5.10246 5.77438 5.09163 5.78521 5.0808 5.79605C2.13413 10.2052 1.32163 14.4952 1.72246 18.7419C1.72246 18.7635 1.7333 18.7852 1.75496 18.796C3.70496 20.226 5.57913 21.0927 7.43163 21.6669C7.46413 21.6777 7.49663 21.6669 7.50746 21.6452C7.9408 21.0494 8.3308 20.421 8.66663 19.7602C8.6883 19.7169 8.66663 19.6735 8.6233 19.6627C8.0058 19.4244 7.4208 19.1427 6.84663 18.8177C6.8033 18.796 6.8033 18.731 6.8358 18.6985C6.95496 18.6119 7.07413 18.5144 7.1933 18.4277C7.21496 18.406 7.24746 18.406 7.26913 18.4169C10.9958 20.1177 15.015 20.1177 18.6983 18.4169C18.72 18.406 18.7525 18.406 18.7741 18.4277C18.8933 18.5252 19.0125 18.6119 19.1316 18.7094C19.175 18.7419 19.175 18.8069 19.1208 18.8285C18.5575 19.1644 17.9616 19.4352 17.3441 19.6735C17.3008 19.6844 17.29 19.7385 17.3008 19.771C17.6475 20.4319 18.0375 21.0602 18.46 21.656C18.4925 21.6669 18.525 21.6777 18.5575 21.6669C20.4208 21.0927 22.295 20.226 24.245 18.796C24.2666 18.7852 24.2775 18.7635 24.2775 18.7419C24.7541 13.8344 23.4866 9.57688 20.9191 5.79605C20.9083 5.78521 20.8975 5.77438 20.8758 5.77438ZM9.22996 16.1527C8.11413 16.1527 7.18246 15.1235 7.18246 13.856C7.18246 12.5885 8.09246 11.5594 9.22996 11.5594C10.3783 11.5594 11.2883 12.5994 11.2775 13.856C11.2775 15.1235 10.3675 16.1527 9.22996 16.1527ZM16.7808 16.1527C15.665 16.1527 14.7333 15.1235 14.7333 13.856C14.7333 12.5885 15.6433 11.5594 16.7808 11.5594C17.9291 11.5594 18.8391 12.5994 18.8283 13.856C18.8283 15.1235 17.9291 16.1527 16.7808 16.1527Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'DiscordIcon'
}
</script>
