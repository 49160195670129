<template>
  <div class="sh-plus sh-dashed">
    <div class="sh overflow-hidden roadmap">
      <div class="container">
        <div class="sh-header text-sm-center">
          <div class="sh-tag">
            <div class="sh-tag-text">
              <div class="sh-tag-shadow">{{ $t("about-text-43") }}</div>
            </div>
          </div>
          <h2 class="sh-title">{{ $t("about-text-44") }}</h2>
        </div>

        <div class="row plan-row swiper-wrapper roadmap-wrapper">
          <div class="roadmap-item">
            <div class="roadmap-item-header">2019 - 2020</div>
            <div class="row roadmap-item-row gy-base">
              <div class="col-3 col-md-4 col-sm-3">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div
                      class="roadmap-box-shadow"
                      style="--shadow-color: #447d98"></div>
                    <div class="box-line">
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-1.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-45") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-46") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 col-md-4 col-sm-3">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div
                      class="roadmap-box-shadow"
                      style="--shadow-color: #447d98"></div>
                    <div class="box-line">
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-1.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-47") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-48") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-sm-6">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div
                      class="roadmap-box-shadow"
                      style="--shadow-color: #edaa7a"></div>
                    <div class="box-line">
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-2.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-49") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-50") }}
                      </div>
                      <div class="roadmap-more text-center d-xl-none">
                        <span
                          class="text-blue"
                          style="cursor: pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#messageRoadmap"
                          @click="openModal(0)"
                          >{{ $t("about-text-60") }}
                        </span>
                      </div>
                    </div>
                    <div class="box-line d-none d-xl-block">
                      <ul class="check-list" style="column-count: 1">
                        <li>{{ $t("about-text-51") }}</li>
                        <li>
                          {{ $t("about-text-52") }}
                        </li>
                        <li>{{ $t("about-text-53") }}</li>
                        <li>{{ $t("about-text-54") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="roadmap-item">
            <div class="roadmap-item-header">2021</div>
            <div class="row roadmap-item-row gy-base">
              <div class="col-4 col-sm-6 col-md-6">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div class="box-line">
                      <div
                        class="roadmap-box-shadow"
                        style="--shadow-color: #447d98"></div>
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-1.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-55") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-56") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 col-sm-6 col-md-6">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div
                      class="roadmap-box-shadow"
                      style="--shadow-color: #edaa7a"></div>
                    <div class="box-line">
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-2.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-57") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-58") }}
                      </div>
                      <div class="roadmap-more text-center d-xl-none">
                        <span
                          class="text-blue"
                          data-bs-toggle="modal"
                          style="cursor: pointer"
                          data-bs-target="#messageRoadmap"
                          @click="openModal(1)"
                          >{{ $t("about-text-60") }}
                        </span>
                      </div>
                    </div>
                    <div class="box-line d-none d-xl-block">
                      <ul class="check-list">
                        <li>{{ $t("about-text-59") }}</li>
                        <li>{{ $t("about-text-61") }}</li>
                        <li>
                          {{ $t("about-text-62") }}
                        </li>
                        <li>
                          {{ $t("about-text-63") }}
                        </li>
                        <li>{{ $t("about-text-64") }}</li>
                        <li>{{ $t("about-text-65") }}</li>
                        <li>{{ $t("about-text-67") }}</li>
                        <li>{{ $t("about-text-68") }}</li>
                        <li>{{ $t("about-text-69") }}</li>
                        <li>{{ $t("about-text-70") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="roadmap-item">
            <div class="roadmap-item-header">2022</div>
            <div class="row roadmap-item-row gy-base">
              <div class="col-xl-4 col-md-6 col">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div class="box-line">
                      <div
                        class="roadmap-box-shadow"
                        style="--shadow-color: #447d98"></div>
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-1.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-71") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-72") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 col-md-6 col">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div
                      class="roadmap-box-shadow"
                      style="--shadow-color: #edaa7a"></div>
                    <div class="box-line">
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-2.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-73") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-74") }}
                      </div>
                      <div class="roadmap-more text-center d-xl-none">
                        <span
                          class="text-blue"
                          @click="openModal(2)"
                          data-bs-toggle="modal"
                          data-bs-target="#messageRoadmap"
                          >{{ $t("about-text-60") }}
                        </span>
                      </div>
                    </div>
                    <div class="box-line d-none d-xl-block">
                      <ul class="check-list">
                        <li>{{ $t("about-text-75") }}</li>
                        <li>{{ $t("about-text-76") }}</li>
                        <li>{{ $t("about-text-77") }}</li>
                        <li>{{ $t("about-text-78") }}</li>
                        <li>{{ $t("about-text-79") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="roadmap-item">
            <div class="roadmap-item-header">2023</div>
            <div class="row roadmap-item-row gy-base">
              <div class="col-xl-8 col">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div
                      class="roadmap-box-shadow"
                      style="--shadow-color: #edaa7a"></div>
                    <div class="box-line">
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-2.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-80") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-81") }}
                      </div>
                      <div class="roadmap-more text-center d-xl-none">
                        <span
                          class="text-blue"
                          data-bs-toggle="modal"
                          style="cursor: pointer"
                          data-bs-target="#messageRoadmap"
                          @click="openModal(3)"
                          >{{ $t("about-text-60") }}
                        </span>
                      </div>
                    </div>
                    <div class="box-line d-none d-xl-block">
                      <ul class="check-list">
                        <li>{{ $t("about-text-82") }}</li>
                        <li>{{ $t("about-text-83") }}</li>
                        <li>{{ $t("about-text-84") }}</li>
                        <li>{{ $t("about-text-85") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div class="box-line">
                      <div
                        class="roadmap-box-shadow"
                        style="--shadow-color: #447d98"></div>
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-1.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-86") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-87") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="roadmap-item w-half">
            <div class="roadmap-item-header">2024 - 2025</div>
            <div class="row roadmap-item-row gy-base">
              <div class="col-12">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div
                      class="roadmap-box-shadow"
                      style="--shadow-color: #edaa7a"></div>
                    <div class="box-line">
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-2.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-88") }}
                      </h5>
                      <div class="roadmap-box-desc">
                        {{ $t("about-text-89") }}
                      </div>
                      <div class="roadmap-more text-center d-xl-none">
                        <span
                          class="text-blue"
                          data-bs-toggle="modal"
                          style="cursor: pointer"
                          data-bs-target="#messageRoadmap"
                          @click="openModal(4)"
                          >{{ $t("about-text-60") }}
                        </span>
                      </div>
                    </div>
                    <div class="box-line d-none d-xl-block">
                      <ul class="check-list">
                        <li>{{ $t("about-text-90") }}</li>
                        <li>{{ $t("about-text-91") }}</li>
                        <li>{{ $t("about-text-92") }}</li>
                        <li>{{ $t("about-text-93") }}</li>
                        <li>{{ $t("about-text-94") }}</li>
                        <li>{{ $t("about-text-95") }}</li>
                        <li>{{ $t("about-text-96") }}</li>
                        <li>{{ $t("about-text-97") }}</li>
                        <li>{{ $t("about-text-98") }}</li>
                        <li>{{ $t("about-text-99") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="roadmap-item w-half">
            <div class="roadmap-item-header">2025 - 2026</div>
            <div class="row roadmap-item-row gy-base">
              <div class="col-12">
                <div class="box roadmap-box">
                  <div class="box-inner p-0 roadmap-box-inner">
                    <div
                      class="roadmap-box-shadow"
                      style="--shadow-color: #edaa7a"></div>
                    <div class="box-line">
                      <div class="roadmap-box-icon">
                        <img
                          src="@/assets/images/roadmap-icon-2.svg"
                          alt="coinfuze" />
                      </div>
                      <h5 class="roadmap-box-title">
                        {{ $t("about-text-101") }}
                      </h5>
                      <div class="roadmap-more text-center d-xl-none">
                        <span
                          class="text-blue"
                          style="cursor: pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#messageRoadmap"
                          @click="openModal(5)"
                          >{{ $t("about-text-60") }}
                        </span>
                      </div>
                    </div>
                    <div class="box-line d-none d-xl-block">
                      <ul class="check-list">
                        <li>{{ $t("about-text-102") }}</li>
                        <li>{{ $t("about-text-103") }}</li>
                        <li>{{ $t("about-text-104") }}</li>
                        <li>{{ $t("about-text-105") }}</li>
                        <li>{{ $t("about-text-106") }}</li>
                        <li>{{ $t("about-text-107") }}</li>
                        <li>{{ $t("about-text-108") }}</li>
                        <li>{{ $t("about-text-109") }}</li>
                        <li>{{ $t("about-text-110") }}</li>
                        <li>{{ $t("about-text-111") }}</li>
                        <li>{{ $t("about-text-112") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <about-roadmap-modal
      v-if="modalShow"
      :content="content[selectedModalContent]"
      @close-modal="closeModal" />
  </div>
</template>
<script>
import AboutRoadmapModal from "./AboutRoadmapModal.vue";
export default {
  name: "AboutRoadmapNative",
  components: {
    AboutRoadmapModal,
  },
  data() {
    return {
      selectedModalContent: 0,
      modalShow: false,
    };
  },
  computed: {
    content() {
      return [
        {
          title: this.$t("about-text-49"),
          description: this.$t("about-text-50"),
          list: [
            this.$t("about-text-51"),
            this.$t("about-text-52"),
            this.$t("about-text-53"),
            this.$t("about-text-54"),
          ],
        },
        {
          title: this.$t("about-text-57"),
          description: this.$t("about-text-58"),
          list: [
            this.$t("about-text-59"),
            this.$t("about-text-61"),
            this.$t("about-text-62"),
            this.$t("about-text-63"),
            this.$t("about-text-64"),
            this.$t("about-text-65"),
            this.$t("about-text-67"),
            this.$t("about-text-68"),
            this.$t("about-text-69"),
            this.$t("about-text-70"),
          ],
        },
        {
          title: this.$t("about-text-73"),
          description: this.$t("about-text-74"),
          list: [
            this.$t("about-text-75"),
            this.$t("about-text-76"),
            this.$t("about-text-77"),
            this.$t("about-text-78"),
            this.$t("about-text-79"),
          ],
        },
        {
          title: this.$t("about-text-80"),
          description: this.$t("about-text-81"),
          list: [
            this.$t("about-text-82"),
            this.$t("about-text-83"),
            this.$t("about-text-84"),
            this.$t("about-text-85"),
          ],
        },
        {
          title: this.$t("about-text-88"),
          description: this.$t("about-text-89"),
          list: [
            this.$t("about-text-90"),
            this.$t("about-text-91"),
            this.$t("about-text-92"),
            this.$t("about-text-93"),
            this.$t("about-text-94"),
            this.$t("about-text-95"),
            this.$t("about-text-96"),
            this.$t("about-text-97"),
            this.$t("about-text-98"),
            this.$t("about-text-99"),
          ],
        },
        {
          title: this.$t("about-text-101"),
          description: "",
          list: [
            this.$t("about-text-102"),
            this.$t("about-text-103"),
            this.$t("about-text-104"),
            this.$t("about-text-105"),
            this.$t("about-text-106"),
            this.$t("about-text-107"),
            this.$t("about-text-108"),
            this.$t("about-text-109"),
            this.$t("about-text-110"),
            this.$t("about-text-111"),
            this.$t("about-text-112"),
          ],
        },
      ];
    },
  },
  methods: {
    openModal(contentIdx) {
      this.selectedModalContent = contentIdx;
      this.modalShow = true;
    },
    closeModal() {
      this.modalShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.roadmap-wrapper {
  overflow-x: auto;
  padding-bottom: 20px !important;

  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 10px;
    background-color: #12171d;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #12171d;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ffffff;
  }

  scrollbar-width: thin;
  scrollbar-color: #ffffff #12171d;
}
.roadmap-item {
  min-width: 70vw;
  @media (max-width: 992px) {
    min-width: 100vw;
  }
  @media (max-width: 768px) {
    min-width: 120vw;
  }
  @media (max-width: 675px) {
    min-width: 170vw;
  }
  @media (max-width: 575px) {
    min-width: 200vw;
  }
  @media (max-width: 399px) {
    min-width: 300vw;
  }
  @media (max-width: 375px) {
    min-width: 230vw;
  }
}
.roadmap-item-row {
  flex-wrap: nowrap;
}
.w-half {
  min-width: 50vw !important;
}
</style>
