import axios from "axios";
import Cookies from "js-cookie";
import * as types from "../mutation-types";

// state
export const state = {
  user: null,
  statistics: null,
  token: Cookies.get("token"),
  mainStats: {
    average_transactions: 800,
    capital: 0,
    clients: 0,
    created: "2024-03-18T14:34:26.236533Z",
    edited: "2024-03-18T14:34:26.236585Z",
    id: 1,
    profitability: 27,
  },
  status: {
    website: 100,
    api: 100,
    hoppers: 100,
    tikers: 100,
    orderbooks: 100,
    charts: 100,
    paper_swap: 100,
    sockets: 100,
  },
};

// getters
export const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  getCapital: (state) => state.mainStats.capital,
  getClients: (state) => state.mainStats.clients,
  getStatistics: (state) => state.statistics,
  getStatus: (state) => state.status,
  check: (state) => state.user !== null,
};

// mutations
export const mutations = {
  UPDATE_STATUS(state, data) {
    Object.assign(state.status, data);
  },
  SET_STATISTICS(state, stats) {
    state.statistics = stats;
  },
  ADD_ALL_PERIOD_DATA(state, data) {
    state.statistics.unshift(data);
  },
  SET_MAIN_STATS(state, data) {
    Object.assign(state.mainStats, data);
  },
  [types.SAVE_TOKEN](state, { token, remember }) {
    state.token = token;
    Cookies.set("token", token, { expires: remember ? 365 : null });
  },

  [types.FETCH_USER_SUCCESS](state, { user }) {
    state.user = user;
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null;
    Cookies.remove("token");
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.token = null;

    Cookies.remove("token");
  },

  [types.UPDATE_USER](state, { user }) {
    state.user = user;
  },
};
/* eslint-disable */
// actions
export const actions = {
  saveToken({ commit, dispatch }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  async fetchUser({ commit }) {
    try {
      const { data } = await axios.get("/api/user");

      commit(types.FETCH_USER_SUCCESS, { user: data });
    } catch (e) {
      commit(types.FETCH_USER_FAILURE);
    }
  },
  getStatisticsData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("https://custxperts.com/api/p_statistics/")
        .then((response) => {
          const statisticsWithPercent = response.data.results.map((item) => {
            return {
              ...item,
              trades_profitable_percent:
                item.trades_total > 0
                  ? (
                      (item.trades_profitable / item.trades_total) *
                      100
                    ).toFixed(2)
                  : 0,
            };
          });
          commit("SET_STATISTICS", statisticsWithPercent);
          let res = response.data.results;
          let tradesT = res.reduce((sum, item) => sum + item.trades_total, 0);
          let tradesP = res.reduce(
            (sum, item) => sum + item.trades_profitable,
            0
          );
          let AllPeriodData = {
            created: "All Period",
            profit_by_month_usdt: res[0].profit_comulative_usdt,
            profit_by_month_percent: res[0].profit_comulative_percent,
            initial_balance: res[res.length - 1].initial_balance,
            final_balance: res[0].final_balance,
            trades_total: tradesT,
            link: false,
            trades_profitable: tradesP,
            net_profits_data: res
              .map((object) => [object.created, object.profit_by_month_usdt])
              .reverse(),
            trades_profitable_percent: ((tradesP / tradesT) * 100).toFixed(2),
          };
          commit("ADD_ALL_PERIOD_DATA", AllPeriodData);
          resolve(response.data.results);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMainStatsData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("https://custxperts.com/api/main_page_data/")
        .then((response) => {
          commit("SET_MAIN_STATS", response.data[0]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateUser({ commit }, payload) {
    commit(types.UPDATE_USER, payload);
  },

  async logout({ commit }) {
    try {
      await axios.post("/api/logout");
    } catch (e) {}

    commit(types.LOGOUT);
  },

  async fetchOauthUrl(ctx, { provider }) {
    const { data } = await axios.post(`/api/oauth/${provider}`);

    return data.url;
  },
};
