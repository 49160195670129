// lang.js
export const SET_LOCALE = "SET_LOCALE";

// auth.js
export const LOGOUT = "LOGOUT";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";

export const BASE_API_URL = "https://custxperts.com/api/";
// export const BASE_API_URL = 'http://127.0.0.1:8000/api/'
