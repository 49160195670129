<template>
  <div class="sh-header text-sm-center">
    <div class="sh-tag">
      <div class="sh-tag-text">
        <div class="sh-tag-shadow">{{ $t("stat-text-1") }}</div>
      </div>
    </div>
    <h1 class="sh-title statistics-title">
      {{ $t("stat-text-2") }} <span>ATS 250 2.0d</span>
    </h1>
  </div>
</template>

<script>
export default {
  name: "StatisticBanner",
};
</script>
