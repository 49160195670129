<template>
  <div class="sh sh-plus sh-line hero">
    <div class="hero-bg hero-bg-2 d-flex-center">
      <img src="@/assets/images/hero-img-2.png" alt="coinfuze" />
    </div>
    <div class="container">
      <div
        class="hero-inner d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="hero-content m-0">
          <div class="sh-header">
            <h1 class="sh-title">
              {{ $t("status-text-1") }}<br /><span>{{
                $t("status-text-2")
              }}</span>
            </h1>
            <p class="sh-desc">
              {{ $t("status-text-3") }}
            </p>
          </div>
        </div>
        <div class="hero-stats">
          <div class="hero-stats-item">
            <div class="hero-stats-item-val">100%</div>
            <div class="hero-stats-item-text">{{ $t("status-text-4") }}</div>
          </div>
          <div class="hero-stats-item">
            <div class="hero-stats-item-val">100%</div>
            <div class="hero-stats-item-text">{{ $t("status-text-5") }}</div>
          </div>
          <div class="hero-stats-item">
            <div class="hero-stats-item-val">99%</div>
            <div class="hero-stats-item-text">{{ $t("status-text-6") }}</div>
          </div>
          <div class="hero-stats-item">
            <div class="hero-stats-item-val">100%</div>
            <div class="hero-stats-item-text">{{ $t("status-text-7") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusBanner",
};
</script>

<style lang="scss"></style>
