<template>
  <div class="sh sh-plus sh-dashed story">
    <div class="container">
      <div class="sh-header text-sm-center">
        <div class="sh-tag">
          <div class="sh-tag-text">
            <div class="sh-tag-shadow">{{ $t("about-text-13") }}</div>
          </div>
        </div>
        <h2 class="sh-title">{{ $t("about-text-14") }}</h2>
      </div>
      <div class="row gy-base story-row">
        <div class="col-lg-6">
          <div class="story-img">
            <img
              class="d-block w-100"
              src="@/assets/images/about-img.webp"
              alt="coinfuze" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="story-content">
            <h5>
              {{ $t("about-text-15") }}
            </h5>
            <p>
              {{ $t("about-text-16") }}
            </p>
            <h5>{{ $t("about-text-17") }}</h5>
            <p>
              {{ $t("about-text-18") }}
            </p>
            <h5>{{ $t("about-text-19") }}</h5>
            <p>
              {{ $t("about-text-20") }}
            </p>
            <h5>{{ $t("about-text-21") }}</h5>
            <p>
              {{ $t("about-text-22") }}
            </p>
            <p>
              {{ $t("about-text-23") }}
            </p>
            <p>
              {{ $t("about-text-24") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { gsap } from "gsap"
// import ScrollTrigger from "gsap/ScrollTrigger"
// gsap.registerPlugin(ScrollTrigger)
export default {
  name: "AboutStory",
  // mounted() {
  //   gsap.to(".story__progress-bar", {
  //     ease: "none",
  //     value: 100,
  //     scrollTrigger: {
  //       start: `top center`,
  //       end: "bottom center",
  //       onUpdate: () => {
  //         let progressBar = document.querySelector(".story__progress-bar")
  //         if (progressBar) {
  //           let percentVal = progressBar.getAttribute("value")

  //           document.querySelector(
  //             ".progress__full"
  //           ).style.height = `${percentVal}%`
  //           if (percentVal >= 2) {
  //             document.querySelector(".story__item-title.first").style.color =
  //               "rgb(20, 241, 149)"
  //           } else {
  //             document.querySelector(".story__item-title.first").style.color =
  //               "#c5d0d9"
  //           }
  //           if (percentVal >= 12) {
  //             document.querySelector(".story__item-title.second").style.color =
  //               "rgb(20, 241, 149)"
  //           } else {
  //             document.querySelector(".story__item-title.second").style.color =
  //               "#c5d0d9"
  //           }
  //           if (percentVal >= 23) {
  //             document.querySelector(".story__item-title.third").style.color =
  //               "rgb(20, 241, 149)"
  //           } else {
  //             document.querySelector(".story__item-title.third").style.color =
  //               "#c5d0d9"
  //           }
  //           if (percentVal >= 33) {
  //             document.querySelector(".story__item-title.fourth").style.color =
  //               "rgb(20, 241, 149)"
  //           } else {
  //             document.querySelector(".story__item-title.fourth").style.color =
  //               "#c5d0d9"
  //           }
  //           if (percentVal >= 46) {
  //             document.querySelector(".story__item-title.five").style.color =
  //               "rgb(20, 241, 149)"
  //           } else {
  //             document.querySelector(".story__item-title.five").style.color =
  //               "#c5d0d9"
  //           }
  //           if (percentVal >= 58) {
  //             document.querySelector(".story__item-title.six").style.color =
  //               "rgb(20, 241, 149)"
  //           } else {
  //             document.querySelector(".story__item-title.six").style.color =
  //               "#c5d0d9"
  //           }
  //           if (percentVal >= 71) {
  //             document.querySelector(".story__item-title.seven").style.color =
  //               "rgb(20, 241, 149)"
  //           } else {
  //             document.querySelector(".story__item-title.seven").style.color =
  //               "#c5d0d9"
  //           }
  //           if (percentVal >= 90) {
  //             document.querySelector(".story__item-title.eight").style.color =
  //               "rgb(20, 241, 149)"
  //           } else {
  //             document.querySelector(".story__item-title.eight").style.color =
  //               "#c5d0d9"
  //           }
  //           // console.log(percentVal)
  //         }
  //       },
  //       trigger: "#mySection",
  //       scrub: 0.3,
  //     },
  //   })
  // },
};
</script>

<!-- <style lang="scss">
.story {
  padding-top: 100px;
  &__wrap {
    position: relative;
    width: 100%;
    flex: 0 0 auto;
    padding: 0 20px;
  }
  &__container {
    height: inherit;
    background-image: url(../../assets/about-bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
  }
  &__title {
    // color: $main-text;
    font-weight: bold;
    padding-left: 40px;
    padding-bottom: 40px;
    font-size: 56px;
    @media (max-width: 1300px) {
      font-size: 38px;
    }
    @media (max-width: 1024px) {
      font-size: 36px;
    }
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
  &__progress-bar {
    display: none;
  }
  &__progress-bar--show {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    // background-color: $progress-bar;
    left: 175px;
    @media (max-width: 768px) {
      left: 115px;
    }
    .progress__full {
      position: absolute;
      height: 0;
      // background: $progress-bar-full;
      width: 5px;
      display: block;
      transition: all 0.5s ease-in-out;
    }
  }
  &__item {
    display: flex;
    padding: 30px 0 155px 0;
    &:last-child {
      padding-bottom: 0;
    }
    @media (max-width: 768px) {
      padding: 30px 0 30px 0;
    }
  }
  &__item-title {
    padding-right: 80px;
    // color: $progress-bar;
    font-size: 46px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  &__item-text {
    // color: $main-text;
    font-size: 24px;
    @media (max-width: 990px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}
</style> -->
