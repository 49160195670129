<template>
  <div class="sh sh-plus sh-line hero">
    <div class="hero-bg d-flex-center">
      <img
        v-if="isWideScreen"
        src="@/assets/images/hero-img.webp"
        width="100%"
        alt="coinfuze" />
      <img
        v-else
        src="@/assets/images/hero-img-m.webp"
        width="100%"
        alt="coinfuze" />
    </div>
    <div class="container">
      <div
        class="hero-inner d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="hero-content">
          <div class="sh-header">
            <div class="sh-tag">
              <div class="sh-tag-text">
                <div class="sh-tag-shadow">{{ $t("home-text-33") }}</div>
              </div>
            </div>
            <h1 class="sh-title">
              We Make<br />Crypto Work For
              <span>Everyone!</span>
            </h1>
            <p class="sh-desc">
              {{ $t("home-text-37") }}
            </p>
          </div>
          <a
            class="bt bt-gr bt-gr-ins"
            href="https://ca.coinfuze.com/"
            target="_blank">
            <span>{{ $t("home-text-38") }}</span></a
          >
        </div>
        <div class="hero-stats" v-if="clients">
          <div class="hero-stats-item">
            <div class="hero-stats-item-val">
              {{ clients.toLocaleString() }}
            </div>
            <div class="hero-stats-item-text">{{ $t("home-text-39") }}</div>
          </div>
          <div class="hero-stats-item">
            <div class="hero-stats-item-val">
              {{ capital.toLocaleString() }}
            </div>
            <div class="hero-stats-item-text">{{ $t("home-text-40") }}</div>
          </div>
          <div class="hero-stats-item">
            <div class="hero-stats-item-val">27%</div>
            <div class="hero-stats-item-text">{{ $t("home-text-41") }}</div>
          </div>
          <div class="hero-stats-item">
            <div class="hero-stats-item-val">800</div>
            <div class="hero-stats-item-text">{{ $t("home-text-42") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeHero",
  data() {
    return {
      info: 0,
      isWideScreen: false,
      loading: false,
    };
  },
  created() {
    // this.getUsersCount();
    this.getStatsData();
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenWidth);
  },
  computed: {
    ...mapGetters({
      clients: "auth/getClients",
      capital: "auth/getCapital",
    }),
  },
  methods: {
    checkScreenWidth() {
      this.isWideScreen = window.innerWidth > 768;
    },
    getStatsData() {
      this.loading = true;
      this.$store
        .dispatch("auth/getMainStatsData")
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss">
.hero-bg {
  @media only screen and (max-width: 768px) {
    min-height: 57vw;
  }
}
</style>
