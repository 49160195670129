// import Vue from 'vue'
// import store from '@/store'
// import VueI18n from 'vue-i18n'

// Vue.use(VueI18n)

// const i18n = new VueI18n({
//   locale: 'en',
//   messages: {}
// })

// /**
//  * @param {String} locale
//  */
// export async function loadMessages (locale) {
//   if (Object.keys(i18n.getLocaleMessage(locale)).length === 0) {
//     const messages = await import(/* webpackChunkName: '' */ `@/lang/${locale}`)
//     i18n.setLocaleMessage(locale, messages)
//   }

//   if (i18n.locale !== locale) {
//     i18n.locale = locale
//   }
// }

// (async function () {
//   await loadMessages(store.getters['lang/locale'])
// })()

// export default i18n
import en from "../lang/en";
import es from "../lang/es";
import it from "../lang/it";
import nl from "../lang/nl";
import ru from "../lang/ru";
import tr from "../lang/tr";
import de from "../lang/de";
import fa from "../lang/fa";

import Vue from "vue";
import store from "@/store";
import VueI18n from "vue-i18n";

const getLocaleFromCookie = () => {
  const topLevelDomain = ".coinfuze.com";
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => {
      const [key, value] = row.split("=");
      return key === "locale" && value && value.endsWith(topLevelDomain);
    })
    ?.split("=")[1];
  return cookieValue ? decodeURIComponent(cookieValue) : null;
};

const getStoreLocale = store.getters["lang/locale"];
const getBrowserLocale = () => {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) return undefined;

  return navigatorLocale.trim().split("-").shift();
};

const defaultLanguage = getLocaleFromCookie()
  ? getLocaleFromCookie()
  : getStoreLocale
  ? getStoreLocale
  : ["de", "en", "es", "it", "nl", "fa", "ru", "tr"].includes(
      getBrowserLocale()
    )
  ? getBrowserLocale()
  : "en";
store.dispatch("lang/setLocale", defaultLanguage);

const messages = {
  de,
  en,
  es,
  it,
  nl,
  ru,
  tr,
  fa,
};

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: "en",
  messages: messages,
});
export default i18n;
