<template>
  <div
    class="modal fade show"
    style="display: block"
    id="messageRoadmap"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-0">
        <div class="box roadmap-box">
          <div class="box-inner p-0 roadmap-box-inner">
            <button
             :class="{ 'modal-close': true, 'fa': $i18n.locale === 'fa' }"
              type="button"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.863757 0.861341C1.19357 0.531527 1.7283 0.531527 2.05812 0.861341L13.4046 12.2078C13.7344 12.5376 13.7344 13.0723 13.4046 13.4021C13.0747 13.732 12.54 13.7319 12.2102 13.4021L0.863757 2.0557C0.533944 1.72589 0.533943 1.19115 0.863757 0.861341Z"
                  fill="#625B7A" />
                <path
                  d="M0.862806 13.4022C0.532992 13.0723 0.532992 12.5376 0.862806 12.2078L12.2092 0.861356C12.5391 0.531542 13.0738 0.531541 13.4036 0.861355C13.7334 1.19117 13.7334 1.7259 13.4036 2.05572L2.05717 13.4021C1.72735 13.732 1.19262 13.732 0.862806 13.4022Z"
                  fill="#625B7A" />
              </svg>
            </button>

            <div class="box-line">
              <div class="roadmap-box-icon">
                <img src="@/assets/images/roadmap-icon-2.svg" alt="coinfuze" />
              </div>
              <h5 class="roadmap-box-title">
                {{ content.title }}
              </h5>
              <div class="roadmap-box-desc">
                {{ content.description }}
              </div>
            </div>
            <div class="box-line">
              <ul class="check-list">
                <li v-for="(item, idx) in content.list" :key="idx + item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AboutRoadmapModal",
  components: {},
  mixins: [],
  props: {
    content: {
      require: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content {
  background-color: #0d0e11 !important;
}
.modal-close {
  border: none;
  z-index: 10;
  top: 5px;
  right: 15px;
  position: absolute;
  background: transparent;
}
.fa .modal-close {
  left: 15px;
  right: auto;
}
.fa {
  left: 15px !important;
  right: auto !important;

}
</style>
