<template>
  <div class="sh sh-plus sh-dashed steps">
    <div class="container">
      <div class="sh-header text-sm-center">
        <h2 class="sh-title">{{ $t("guide-text-5") }}</h2>
      </div>
      <div class="row gy-base">
        <div class="col-lg-6">
          <div class="box steps-box h-100">
            <div class="box-inner steps-box-inner p-0">
              <div class="steps-box-num">01</div>
              <div class="steps-box-img">
                <!-- <img src="@/assets/images/test.png" alt="coinfuze" /> -->
                <img
                  v-if="locale === 'ru'"
                  src="@/assets/images/step-img-1.png"
                  alt="coinfuze" />
                <img
                  v-else
                  src="@/assets/images/step-img-1.png"
                  alt="coinfuze" />
              </div>
              <div class="steps-box-content">
                <h3 class="steps-box-title">{{ $t("guide-text-6") }}</h3>
                <div class="steps-box-desc">
                  <p>
                    {{ $t("guide-text-7") }}
                  </p>
                  <p>
                    {{ $t("guide-text-8") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="box steps-box h-100">
            <div class="box-inner steps-box-inner p-0">
              <div class="steps-box-num">02</div>
              <div class="steps-box-img">
                <img
                  v-if="locale === 'ru'"
                  src="@/assets/images/step-img-2.png"
                  alt="coinfuze" />
                <img
                  v-else
                  src="@/assets/images/step-img-2.png"
                  alt="coinfuze" />
              </div>
              <div class="steps-box-content">
                <h3 class="steps-box-title">{{ $t("guide-text-9") }}</h3>
                <div class="steps-box-desc">
                  <p>
                    {{ $t("guide-text-10") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="box steps-box h-100">
            <div class="box-inner steps-box-inner p-0">
              <div class="steps-box-num">03</div>
              <div class="steps-box-img">
                <img
                  v-if="locale === 'ru'"
                  src="@/assets/images/step-img-3.png"
                  alt="coinfuze" />
                <img
                  v-else
                  src="@/assets/images/step-img-3.png"
                  alt="coinfuze" />
              </div>
              <div class="steps-box-content">
                <h3 class="steps-box-title">{{ $t("guide-text-11") }}</h3>
                <div class="steps-box-desc">
                  <p>
                    {{ $t("guide-text-12") }}
                  </p>
                  <p>
                    {{ $t("guide-text-13") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="box steps-box h-100">
            <div class="box-inner steps-box-inner p-0">
              <div class="steps-box-num">04</div>
              <div class="steps-box-img custom">
                <img
                  v-if="locale === 'ru'"
                  src="@/assets/images/step-img-4.png"
                  alt="coinfuze" />
                <img
                  v-else
                  src="@/assets/images/step-img-4.png"
                  alt="coinfuze" />
              </div>
              <div class="steps-box-content">
                <h3 class="steps-box-title">{{ $t("guide-text-14") }}</h3>
                <div class="steps-box-desc">
                  <p>
                    {{ $t("guide-text-15") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GuideAdvantage",
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },
};
</script>
