<script>
import SocketClient from "@/plugins/socket-client";
export default {
  computed: {
    socketOptions() {
      let options = {};
      return options;
    },
  },
  beforeMount() {
    this.connectSocket(this.socketOptions);
  },
  beforeDestroy() {
    this.disconnectSocket();
  },
  methods: {
    connectSocket(options) {
      SocketClient.connect(options)
        .then(() => {
          SocketClient.on("message", (response) =>
            this.responseHandler(response)
          );
        })
        .catch((error) => {
          console.error("Socket connection error:", error);
        })
        .finally(() => {
          this.sendMessage();
        });
    },
    disconnectSocket() {
      SocketClient.off("message", this.responseHandler);
      SocketClient.disconnect();
    },
    sendMessage() {
      SocketClient.emit("message", { message: "hello it`s me :)" });
    },
    responseHandler(response) {
      const keysToMutations = {
        status: "auth/UPDATE_STATUS",
        anotherKey: "commitForAnotherKey", //example
      };

      Object.entries(keysToMutations).forEach(([key, mutation]) => {
        if (response[key] !== null && response[key] !== undefined) {
          this.$store.commit(mutation, response[key]);
        }
      });
    },
  },
};
</script>
