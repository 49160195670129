<template>
  <div class="sh sh-plus sh-dashed success">
    <div class="container">
      <div class="sh-header text-sm-center">
        <div class="sh-tag">
          <div class="sh-tag-text">
            <div class="sh-tag-shadow">{{ $t("home-text-18") }}</div>
          </div>
        </div>
        <h2 class="sh-title">
          {{ $t("home-text-19") }}
        </h2>
        <p class="sh-desc">
          {{ $t("home-text-20") }}
        </p>
      </div>
      <div class="success-inner">
        <div class="row gy-base">
          <div class="col-12">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: var(--s-blue-color)"></div>
                <div
                  class="success-box-shadow right"
                  style="--shadow-color: var(--s-blue-color)"></div>
                <div class="success-box-icon">
                  <img
                    src="@/assets/images/success-icon-1.svg"
                    alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">
                    {{ $t("home-text-21") }}
                  </h4>
                  <p class="success-box-desc">
                    {{ $t("home-text-22") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: var(--s-orange-color)"></div>
                <div class="success-box-icon">
                  <img
                    src="@/assets/images/success-icon-2.svg"
                    alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">
                    {{ $t("home-text-23") }}
                  </h4>
                  <p class="success-box-desc">
                    {{ $t("home-text-24") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: var(--s-darkviolet-color)"></div>
                <div class="success-box-icon">
                  <img
                    src="@/assets/images/success-icon-3.svg"
                    alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">
                    {{ $t("home-text-25") }}
                  </h4>
                  <p class="success-box-desc">
                    {{ $t("home-text-26") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeSuccess",
};
</script>
