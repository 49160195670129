<template>
  <div class="how-content-item" id="plans">
    <h2 class="how-content-title">{{ $t("investing-text-24") }}</h2>
    <div class="box">
      <div class="box-inner">
        <p>
          {{ $t("investing-text-25") }}
        </p>
        <p class="mb-3">
          {{ $t("investing-text-26") }}
        </p>
        <a
          class="bt bt-gr bt-gr-ins how-content-button mb-5"
          :href="`https://docs.coinfuze.com/${locale}/coinfuze-investment-plans`"
          target="_blank"
          ><span> {{ $t("investing-text-17-1") }}</span></a
        >
        <h4 class="mb-4">{{ $t("investing-text-27") }}</h4>
        <div class="plan-swiper">
          <div class="row plan-row swiper-wrapper plan-wrapper">
            <div class="col">
              <div class="box plan">
                <div class="box-inner plan-inner p-0">
                  <div class="plan-img">
                    <img src="@/assets/images/plan-1.png" alt="coinfuze" />
                  </div>
                  <div class="plan-title fw-600">
                    {{ $t("investing-text-28") }}
                    <span style="color: #e55a19">No.1</span>
                  </div>
                  <table :class="{ 'plan-table': true, 'fa': $i18n.locale === 'fa' }">
                    <tr>
                      <td>{{ $t("investing-text-29") }}</td>
                      <td>
                        <div class="d-flex align-items-center" :style="{ justifyContent: $i18n.locale === 'fa' ? 'unset' : 'flex-end' }">
                          100 USDT
                          <div class="plan-icon">
                            <img
                              src="@/assets/images/usdt.svg"
                              alt="coinfuze" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-30") }}</td>
                      <td>60 {{ $t("investing-text-31") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-32") }}</td>
                      <td>{{ $t("investing-text-33") }} 16%</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-34") }}</td>
                      <td>35%</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="box plan">
                <div class="box-inner plan-inner p-0">
                  <div class="plan-img">
                    <img src="@/assets/images/plan-2.png" alt="coinfuze" />
                  </div>
                  <div class="plan-title fw-600">
                    {{ $t("investing-text-28") }}
                    <span style="color: #37bbc6">No.2</span>
                  </div>
                  <table :class="{ 'plan-table': true, 'fa': $i18n.locale === 'fa' }">
                    <tr>
                      <td>{{ $t("investing-text-29") }}</td>
                      <td>
                        <div class="d-flex align-items-center" :style="{ justifyContent: $i18n.locale === 'fa' ? 'unset' : 'flex-end' }">
                          100 USDT
                          <div class="plan-icon">
                            <img
                              src="@/assets/images/usdt.svg"
                              alt="coinfuze" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-30") }}</td>
                      <td>120 {{ $t("investing-text-31") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-32") }}</td>
                      <td>{{ $t("investing-text-33") }} 35%</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-34") }}</td>
                      <td>30%</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="box plan">
                <div class="box-inner plan-inner p-0">
                  <div class="plan-img">
                    <img src="@/assets/images/plan-3.png" alt="coinfuze" />
                  </div>
                  <div class="plan-title fw-600">
                    {{ $t("investing-text-28") }}
                    <span style="color: #ff2dd1">No.3</span>
                  </div>
                  <table :class="{ 'plan-table': true, 'fa': $i18n.locale === 'fa' }">
                    <tr>
                      <td>{{ $t("investing-text-29") }}</td>
                      <td>
                        <div class="d-flex align-items-center" :style="{ justifyContent: $i18n.locale === 'fa' ? 'unset' : 'flex-end' }">
                          100 USDT
                          <div class="plan-icon">
                            <img
                              src="@/assets/images/usdt.svg"
                              alt="coinfuze" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-30") }}</td>
                      <td>180 {{ $t("investing-text-31") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-32") }}</td>
                      <td>{{ $t("investing-text-33") }} 55%</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-34") }}</td>
                      <td>25%</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="box plan">
                <div class="box-inner plan-inner p-0">
                  <div class="plan-img">
                    <img src="@/assets/images/plan-4.png" alt="coinfuze" />
                  </div>
                  <div class="plan-title fw-600">
                    {{ $t("investing-text-28") }}
                    <span style="color: #10caa9">No.4</span>
                  </div>
                  <table :class="{ 'plan-table': true, 'fa': $i18n.locale === 'fa' }">
                    <tr>
                      <td>{{ $t("investing-text-29") }}</td>
                      <td>
                        <div class="d-flex align-items-center" :style="{ justifyContent: $i18n.locale === 'fa' ? 'unset' : 'flex-end' }">
                          100 USDT
                          <div class="plan-icon">
                            <img
                              src="@/assets/images/usdt.svg"
                              alt="coinfuze" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-30") }}</td>
                      <td>240 {{ $t("investing-text-31") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-32") }}</td>
                      <td>{{ $t("investing-text-33") }} 77%</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-34") }}</td>
                      <td>20%</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="box plan">
                <div class="box-inner plan-inner p-0">
                  <div class="plan-img">
                    <img src="@/assets/images/plan-5.png" alt="coinfuze" />
                  </div>
                  <div class="plan-title fw-600">
                    {{ $t("investing-text-28") }}
                    <span style="color: #42ad00">No.5</span>
                  </div>
                  <table :class="{ 'plan-table': true, 'fa': $i18n.locale === 'fa' }">
                    <tr>
                      <td>{{ $t("investing-text-29") }}</td>
                      <td>
                        <div class="d-flex align-items-center" :style="{ justifyContent: $i18n.locale === 'fa' ? 'unset' : 'flex-end' }">
                          100 USDT
                          <div class="plan-icon">
                            <img
                              src="@/assets/images/usdt.svg"
                              alt="coinfuze" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-30") }}</td>
                      <td>300 {{ $t("investing-text-31") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-32") }}</td>
                      <td>{{ $t("investing-text-33") }} 95%</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-34") }}</td>
                      <td>15%</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="box plan">
                <div class="box-inner plan-inner p-0">
                  <div class="plan-img">
                    <img src="@/assets/images/plan-6.png" alt="coinfuze" />
                  </div>
                  <div class="plan-title fw-600">
                    {{ $t("investing-text-28") }}
                    <span style="color: #5e6eff">No.6</span>
                  </div>
                  <table :class="{ 'plan-table': true, 'fa': $i18n.locale === 'fa' }">
                    <tr>
                      <td>{{ $t("investing-text-29") }}</td>
                      <td>
                        <div class="d-flex align-items-center" :style="{ justifyContent: $i18n.locale === 'fa' ? 'unset' : 'flex-end' }">
                          100 USDT
                          <div class="plan-icon">
                            <img
                              src="@/assets/images/usdt.svg"
                              alt="coinfuze" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-30") }}</td>
                      <td>360 {{ $t("investing-text-31") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-32") }}</td>
                      <td>{{ $t("investing-text-33") }} 120%</td>
                    </tr>
                    <tr>
                      <td>{{ $t("investing-text-34") }}</td>
                      <td>10%</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="swiper-scrollbar plan-scrollbar"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "InvestingPlans",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.plan-wrapper {
  overflow-x: auto;
  padding-bottom: 20px !important;

  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 10px;
    background-color: #12171d;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #12171d;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ffffff;
  }

  scrollbar-width: thin;
  scrollbar-color: #ffffff #12171d;
}
</style>
