import Cookies from "js-cookie";
import * as types from "../mutation-types";
// import { BASE_API_URL } from '../mutation-types'

const lang = {
  locale: "en",
  fallbackLocale: "en",
  locales: {
    ru: "ru",
    en: "en",
    tr: "tr",
    es: "es",
    it: "it",
    de: "de",
    nl: "nl",
    fa: "fa",
  },
};

const { locale, locales } = lang;

// state
export const state = {
  locale: getLocale(locales, locale),
  locales: locales,
  // statistics: null
};

// getters
export const getters = {
  locale: (state) => state.locale,
  locales: (state) => state.locales,
  localesList: (state) => Object.keys(state.locales),
  // statistics: state => state.statistics
};

// mutations
export const mutations = {
  [types.SET_LOCALE](state, locale) {
    const topLevelDomain = ".coinfuze.com";
    document.cookie = `locale=${encodeURIComponent(
      locale
    )};domain=${topLevelDomain};path=/`;
    state.locale = locale;
  },
  // [types.SET_STATISTICS] (state, { statistics }) {
  //   state.statistics = statistics
  // }
};

// actions
export const actions = {
  setLocale({ commit }, locale) {
  console.log('store', locale)
    commit(types.SET_LOCALE, locale);
    // Cookies.set("locale", locale, { expires: 365 });
    // Cookies.set("locale", locale, { expires: 365, domain: ".coinfuze.com" });
  },
};

/**
 * @param  {String[]} locales
 * @param  {String} fallback
 * @return {String}
 */
function getLocale(locales, fallback) {
  const locale = Cookies.get("locale");
  if (Object.prototype.hasOwnProperty.call(locales, locale)) {
    return locale || "en";
  } else if (locale) {
    Cookies.remove("locale");
  }

  return fallback;
}
