<template>
  <div class="how-content-item">
    <h2 class="how-content-title">{{ $t("investing-text-42") }}</h2>
    <p>
      {{ $t("investing-text-43") }}
    </p>
    <p>{{ $t("investing-text-44") }}</p>
    <p>
      {{ $t("investing-text-45") }}
    </p>
    <p>
      {{ $t("investing-text-46") }}
    </p>
  </div>
</template>
<script>
export default {
  name: "InvestingHowBlock",
};
</script>
