<template>
  <div id="app" class="app">
    <!-- <loading ref="loading" /> -->
    <header-item />
    <div style="min-height: 100vh">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <footer-item @open-popup="openPopup" />
    <!-- Modals-->
    <popup-item v-if="popupOpen" @close-popup="closePopup" />
  </div>
</template>

<script>
// import Loading from "@/components/Loading";
import { mapGetters } from "vuex";

import HeaderItem from "./components/HeaderItem.vue";
import FooterItem from "./components/FooterItem.vue";
import { Crisp } from "crisp-sdk-web";
import PopupItem from "./components/PopupItem.vue";
// Load layout components dynamically.
// const requireContext = require.context("@/layouts", false, /.*\.vue$/);

// const layouts = requireContext
//   .keys()
//   .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
//   .reduce((components, [name, component]) => {
//     components[name] = component.default || component;
//     return components;
//   }, {});

export default {
  el: "#app",

  components: {
    // Loading,
    HeaderItem,
    FooterItem,
    PopupItem,
  },

  data: () => ({
    layout: null,
    popupOpen: false,
    defaultLayout: "default",
    isOpen: false,
  }),

  metaInfo: {
    title: "Coinfuze",
  },

  mounted() {
    this.$loading = this.$refs.loading;
    this.configureCrispChat();
    if (this.locale === "fa") {
      document.documentElement.setAttribute("dir", "rtl");
    }
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },

  methods: {
    closePopup() {
      this.popupOpen = false;
    },
    openPopup() {
      this.popupOpen = true;
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    configureCrispChat() {
      let CRISP_WEBSITE_ID = "ae79c611-3e96-48ef-8042-dbc0d6159854";
      Crisp.configure(CRISP_WEBSITE_ID, {
        autoload: true,
        locale: this.$i18n.locale,
      });
    },
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    // setLayout(layout) {
    //   if (!layout || !layouts[layout]) {
    //     layout = this.defaultLayout;
    //   }

    //   this.layout = layouts[layout];
    // },
  },
};
</script>
<style lang="scss">
@import "@/styles/scss/style.scss";
</style>
