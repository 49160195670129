<template>
  <div class="sh sh-plus sh-dashed team">
    <div class="sh-shadow" style="--shadow-color: var(--s-blue-color)"></div>
    <div class="container">
      <div class="sh-header text-sm-center">
        <div class="sh-tag">
          <div class="sh-tag-text">
            <div class="sh-tag-shadow">{{ $t("about-text-33") }}</div>
          </div>
        </div>
        <h2 class="sh-title">{{ $t("about-text-34") }}</h2>
      </div>
      <div class="row gy-base">
        <div class="col-xl-3 col-lg-4 col-md-6">
          <div class="box team-box h-100 text-center">
            <div class="box-inner team-box-inner">
              <div class="box-shadow" style="--shadow-color: #63a1fb"></div>
              <div class="team-box-img">
                <img src="@/assets/images/team-img-1.png" alt="coinfuze" />
              </div>
              <div class="team-box-content">
                <div class="team-box-header">
                  <div class="team-box-name text-white fw-700">
                    Aleksandr Langser
                  </div>
                  <div class="team-box-pos">Co-founder</div>
                  <div
                    class="team-box-socials d-flex align-items-center justify-content-center">
                    <a
                      class="team-box-socials-item d-flex-center"
                      href="https://www.linkedin.com/in/aleksandr-langser-74302b301/"
                      target="_blank">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.04842 2.91676C4.04827 3.22618 3.9252 3.52287 3.7063 3.74155C3.4874 3.96023 3.19059 4.083 2.88117 4.08285C2.57175 4.08269 2.27507 3.95963 2.05638 3.74072C1.8377 3.52182 1.71493 3.22501 1.71509 2.9156C1.71524 2.60618 1.83831 2.30949 2.05721 2.09081C2.27611 1.87212 2.57292 1.74936 2.88234 1.74951C3.19176 1.74967 3.48844 1.87273 3.70713 2.09163C3.92581 2.31053 4.04858 2.60734 4.04842 2.91676ZM4.08342 4.94676H1.75009V12.2501H4.08342V4.94676ZM7.77009 4.94676H5.44842V12.2501H7.74676V8.4176C7.74676 6.2826 10.5293 6.08426 10.5293 8.4176V12.2501H12.8334V7.62426C12.8334 4.02509 8.71509 4.15926 7.74676 5.92676L7.77009 4.94676Z"
                          fill="white" />
                      </svg>
                    </a>

                    <a
                      class="team-box-socials-item d-flex-center"
                      href="https://twitter.com/ALangser_cf"
                      target="_blank"
                      ><svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.1323 6.062L12.5624 0.875H11.5124L7.6668 5.37862L4.5938 0.875H1.05005L5.6963 7.686L1.05005 13.125H2.10005L6.1618 8.3685L9.40717 13.125H12.9509L8.1323 6.062ZM6.69467 7.7455L6.22392 7.06737L2.47805 1.67125H4.09067L7.11292 6.02612L7.58367 6.70425L11.5133 12.3655H9.90067L6.69467 7.7455Z"
                          fill="white" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="team-box-desc">
                  {{ $t("about-text-36") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6">
          <div class="box team-box h-100 text-center">
            <div class="box-inner team-box-inner">
              <div class="box-shadow" style="--shadow-color: #63a1fb"></div>
              <div class="team-box-img">
                <img src="@/assets/images/team-img-2.png" alt="coinfuze" />
              </div>
              <div class="team-box-content">
                <div class="team-box-header">
                  <div class="team-box-name text-white fw-700">Neil Sadiq</div>
                  <div class="team-box-pos">Co-founder</div>
                  <div
                    class="team-box-socials d-flex align-items-center justify-content-center">
                    <a
                      class="team-box-socials-item d-flex-center"
                      href="https://www.linkedin.com/in/neil-sadiq-b56242301/"
                      target="_blank"
                      ><svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.04842 2.91676C4.04827 3.22618 3.9252 3.52287 3.7063 3.74155C3.4874 3.96023 3.19059 4.083 2.88117 4.08285C2.57175 4.08269 2.27507 3.95963 2.05638 3.74072C1.8377 3.52182 1.71493 3.22501 1.71509 2.9156C1.71524 2.60618 1.83831 2.30949 2.05721 2.09081C2.27611 1.87212 2.57292 1.74936 2.88234 1.74951C3.19176 1.74967 3.48844 1.87273 3.70713 2.09163C3.92581 2.31053 4.04858 2.60734 4.04842 2.91676ZM4.08342 4.94676H1.75009V12.2501H4.08342V4.94676ZM7.77009 4.94676H5.44842V12.2501H7.74676V8.4176C7.74676 6.2826 10.5293 6.08426 10.5293 8.4176V12.2501H12.8334V7.62426C12.8334 4.02509 8.71509 4.15926 7.74676 5.92676L7.77009 4.94676Z"
                          fill="white" /></svg
                    ></a>

                    <a
                      class="team-box-socials-item d-flex-center"
                      href="https://twitter.com/NeilSadiqdev_cf"
                      target="_blank"
                      ><svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.1323 6.062L12.5624 0.875H11.5124L7.6668 5.37862L4.5938 0.875H1.05005L5.6963 7.686L1.05005 13.125H2.10005L6.1618 8.3685L9.40717 13.125H12.9509L8.1323 6.062ZM6.69467 7.7455L6.22392 7.06737L2.47805 1.67125H4.09067L7.11292 6.02612L7.58367 6.70425L11.5133 12.3655H9.90067L6.69467 7.7455Z"
                          fill="white" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="team-box-desc">
                  {{ $t("about-text-38") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6">
          <div class="box team-box h-100 text-center">
            <div class="box-inner team-box-inner">
              <div class="box-shadow" style="--shadow-color: #63a1fb"></div>
              <div class="team-box-img">
                <img src="@/assets/images/team-img-3.png" alt="coinfuze" />
              </div>
              <div class="team-box-content">
                <div class="team-box-header">
                  <div class="team-box-name text-white fw-700">
                    Emma Robinson
                  </div>
                  <div class="team-box-pos">Head of Innovations</div>
                  <div
                    class="team-box-socials d-flex align-items-center justify-content-center">
                    <a
                      class="team-box-socials-item d-flex-center"
                      href="https://www.linkedin.com/in/emma-robinson-34b030301/"
                      target="_blank">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.04842 2.91676C4.04827 3.22618 3.9252 3.52287 3.7063 3.74155C3.4874 3.96023 3.19059 4.083 2.88117 4.08285C2.57175 4.08269 2.27507 3.95963 2.05638 3.74072C1.8377 3.52182 1.71493 3.22501 1.71509 2.9156C1.71524 2.60618 1.83831 2.30949 2.05721 2.09081C2.27611 1.87212 2.57292 1.74936 2.88234 1.74951C3.19176 1.74967 3.48844 1.87273 3.70713 2.09163C3.92581 2.31053 4.04858 2.60734 4.04842 2.91676ZM4.08342 4.94676H1.75009V12.2501H4.08342V4.94676ZM7.77009 4.94676H5.44842V12.2501H7.74676V8.4176C7.74676 6.2826 10.5293 6.08426 10.5293 8.4176V12.2501H12.8334V7.62426C12.8334 4.02509 8.71509 4.15926 7.74676 5.92676L7.77009 4.94676Z"
                          fill="white" />
                      </svg>
                    </a>

                    <a
                      class="team-box-socials-item d-flex-center"
                      href="https://twitter.com/EmmaRobinsoncf"
                      target="_blank"
                      ><svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.1323 6.062L12.5624 0.875H11.5124L7.6668 5.37862L4.5938 0.875H1.05005L5.6963 7.686L1.05005 13.125H2.10005L6.1618 8.3685L9.40717 13.125H12.9509L8.1323 6.062ZM6.69467 7.7455L6.22392 7.06737L2.47805 1.67125H4.09067L7.11292 6.02612L7.58367 6.70425L11.5133 12.3655H9.90067L6.69467 7.7455Z"
                          fill="white" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="team-box-desc">
                  {{ $t("about-text-39") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6">
          <div class="box team-box h-100 text-center">
            <div class="box-inner team-box-inner">
              <div class="box-shadow" style="--shadow-color: #63a1fb"></div>
              <div class="team-box-img">
                <img src="@/assets/images/team-img-4.png" alt="coinfuze" />
              </div>
              <div class="team-box-content">
                <div class="team-box-header">
                  <div class="team-box-name text-white fw-700">Mark Kinner</div>
                  <div class="team-box-pos">Head of Development</div>
                  <div
                    class="team-box-socials d-flex align-items-center justify-content-center">
                    <a
                      class="team-box-socials-item d-flex-center"
                      href="https://www.linkedin.com/in/mark-kinner-028244301/"
                      target="_blank">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.04842 2.91676C4.04827 3.22618 3.9252 3.52287 3.7063 3.74155C3.4874 3.96023 3.19059 4.083 2.88117 4.08285C2.57175 4.08269 2.27507 3.95963 2.05638 3.74072C1.8377 3.52182 1.71493 3.22501 1.71509 2.9156C1.71524 2.60618 1.83831 2.30949 2.05721 2.09081C2.27611 1.87212 2.57292 1.74936 2.88234 1.74951C3.19176 1.74967 3.48844 1.87273 3.70713 2.09163C3.92581 2.31053 4.04858 2.60734 4.04842 2.91676ZM4.08342 4.94676H1.75009V12.2501H4.08342V4.94676ZM7.77009 4.94676H5.44842V12.2501H7.74676V8.4176C7.74676 6.2826 10.5293 6.08426 10.5293 8.4176V12.2501H12.8334V7.62426C12.8334 4.02509 8.71509 4.15926 7.74676 5.92676L7.77009 4.94676Z"
                          fill="white" />
                      </svg>
                    </a>

                    <a
                      class="team-box-socials-item d-flex-center"
                      href="https://twitter.com/KinnerMark_cf"
                      target="_blank"
                      ><svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.1323 6.062L12.5624 0.875H11.5124L7.6668 5.37862L4.5938 0.875H1.05005L5.6963 7.686L1.05005 13.125H2.10005L6.1618 8.3685L9.40717 13.125H12.9509L8.1323 6.062ZM6.69467 7.7455L6.22392 7.06737L2.47805 1.67125H4.09067L7.11292 6.02612L7.58367 6.70425L11.5133 12.3655H9.90067L6.69467 7.7455Z"
                          fill="white" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="team-box-desc">
                  {{ $t("about-text-40") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutTeam",
};
</script>
