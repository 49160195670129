<template>
  <div class="sh-plus sh-dashed">
    <div class="sh fundamentals overflow-hidden">
      <div class="container">
        <div class="row gy-base">
          <div class="col-lg-6">
            <div class="about-inner">
              <div class="about-header">
                <div class="sh-header">
                  <div class="sh-tag">
                    <div class="sh-tag-text">
                      <div class="sh-tag-shadow">{{ $t("about-text-5") }}</div>
                    </div>
                  </div>
                  <h2 class="sh-title">
                    {{ $t("about-text-6") }}&nbsp;
                    <br class="d-none d-sm-block" />
                    {{ $t("about-text-7") }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content">
              <h4 class="about-content-title">
                {{ $t("about-text-8") }}
              </h4>
              <p class="mb-2">
                {{ $t("about-text-9") }}
              </p>
              <p class="mb-4">
                {{ $t("about-text-10") }}
              </p>
              <h4 class="about-content-title">{{ $t("about-text-11") }}</h4>
              <p>
                {{ $t("about-text-12") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        class="fundamentals-icon"
        src="@/assets/images/fundamentals-img.png"
        alt="coinfuze" />
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutEcosystem",
};
</script>
