<template>
  <main class="main">
    <guide-banner />
    <guide-advantage />
    <home-community />
  </main>
</template>

<script>
// const HomeCommunity = () => import("@/components/HomePage/HomeCommunity.vue");
// const GuideBanner = () => import("@/components/GuidePage/GuideBanner");
// const GuideAdvantage = () => import("@/components/GuidePage/GuideAdvantage");
import { mapGetters } from "vuex";

import GuideBanner from "@/components/GuidePage/GuideBanner.vue";
import GuideAdvantage from "@/components/GuidePage/GuideAdvantage.vue";
import HomeCommunity from "@/components/HomePage/HomeCommunity.vue";

export default {
  // layout: "inner",
  name: "GuidePage",
  layout: "basic",
  components: {
    GuideBanner,
    HomeCommunity,
    GuideAdvantage,
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },
  mounted() {
    this.scrollToTop();

    //     const script = document.createElement("script");
    //     script.id = "roistat-script";
    //     script.innerHTML = `(function(w, d, s, h, id) {
    // w.roistatProjectId = id; w.roistatHost = h;
    // var p = d.location.protocol == "https:" ? "https://" : "http://";
    // var u = /^.roistat_visit=[^;]+(.)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    // var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
    // })(window, document, 'script', 'cloud.roistat.com', 'f5154a47715fbb5b35fb704cd376a28d');`;
    //     document.body.appendChild(script);
  },
  // beforeDestroy() {
  //   const existingScript = document.getElementById("roistat-script");
  //   if (existingScript) {
  //     existingScript.parentNode.removeChild(existingScript);
  //   }
  // },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://coinfuze.com/en/first-steps",
      },
      headline:
        "CoinFuze: Your First Step into the World of Innovative Investments",
      description:
        "Are you ready to invest for the future? CoinFuze provides support and guidance to make your first steps in investing confident and successful.",
      image: "https://coinfuze.com/img/step-img-1.68713cf1.png",
      author: {
        "@type": "Organization",
        name: "CoinFuze",
        url: "https://coinfuze.com/",
      },
      publisher: {
        "@type": "Organization",
        name: "CoinFuze",
        logo: {
          "@type": "ImageObject",
          url: "https://coinfuze.com/img/logo.60d4afae.svg",
        },
      },
      datePublished: "2018-01-01",
      dateModified: "2024-04-19",
    };
  },
  metaInfo() {
    return {
      title: this.$t("guide-meta-title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("guide-meta-description"),
        },
        {
          name: "og:title",
          content: this.$t("guide-meta-title"),
        },
        {
          name: "og:description",
          content: this.$t("guide-meta-description"),
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://coinfuze.com/${this.locale}/first-steps/`,
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/en/first-steps",
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/first-steps",
        },
        {
          rel: "alternative",
          hreflang: "ru",
          href: "https://coinfuze.com/ru/first-steps",
        },
        {
          rel: "alternative",
          hreflang: "de",
          href: "https://coinfuze.com/de/first-steps",
        },
        {
          rel: "alternative",
          hreflang: "es",
          href: "https://coinfuze.com/es/first-steps",
        },
        {
          rel: "alternative",
          hreflang: "fa",
          href: "https://coinfuze.com/fa/first-steps",
        },
        {
          rel: "alternative",
          hreflang: "it",
          href: "https://coinfuze.com/it/first-steps",
        },
        {
          rel: "alternative",
          hreflang: "nl",
          href: "https://coinfuze.com/nl/first-steps",
        },
        {
          rel: "alternative",
          hreflang: "tr",
          href: "https://coinfuze.com/tr/first-steps",
        },
      ],
    };
  },
};
</script>
