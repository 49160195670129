<template>
  <div class="sh pt-0 program">
    <div class="container">
      <div class="sh-header text-sm-center">
        <h2 class="sh-title sh-title-small">
          {{ $t("affiliate-text-14") }}<br class="d-none d-sm-block" />
          {{ $t("affiliate-text-15") }}
        </h2>
      </div>
      <div class="row program-row">
        <div class="col-lg-4">
          <div class="program-box h-100 d-flex flex-column">
            <div
              class="program-box-shadow"
              style="--shadow-color: #63a0f9"></div>
            <div class="program-box-num text-white fw-700">01</div>
            <div class="program-box-content mt-auto">
              <div class="program-box-title text-white fw-700">
                {{ $t("affiliate-text-16") }}
              </div>
              <div class="program-box-desc">
                {{ $t("affiliate-text-17") }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="program-box h-100 d-flex flex-column">
            <div
              class="program-box-shadow"
              style="--shadow-color: #63a0f9"></div>
            <div class="program-box-num text-white fw-700">02</div>
            <div class="program-box-content mt-auto">
              <div class="program-box-title text-white fw-700">
                {{ $t("affiliate-text-18") }}
              </div>
              <div class="program-box-desc">
                {{ $t("affiliate-text-19") }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="program-box h-100 d-flex flex-column">
            <div
              class="program-box-shadow"
              style="--shadow-color: #63a0f9"></div>
            <div class="program-box-num text-white fw-700">03</div>
            <div class="program-box-content mt-auto">
              <div class="program-box-title text-white fw-700">
                {{ $t("affiliate-text-20") }}
              </div>
              <div class="program-box-desc">
                {{ $t("affiliate-text-21") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AffiliateInfo",
};
</script>
