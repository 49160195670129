<template>
  <div class="col-lg-3">
    <aside class="how-aside h-100">
      <div
        class="how-aside-toggle d-lg-none"
        :class="{ active: menuActive }"
        style="cursor: pointer"
        @click="toggleMenu">
        {{ $t("investing-text-1") }}
      </div>
      <transition name="fade">
        <div class="how-aside-inner" v-if="menuActive">
          <h4 class="how-aside-title fw-500">{{ $t("investing-text-2") }}</h4>
          <div class="how-aside-item">
            <ul class="reset-list how-aside-list">
              <li>
                <a class="anchor" href="#plans"
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 0H1.5C0.671573 0 0 0.671573 0 1.5V5.5C0 6.32843 0.671573 7 1.5 7H5.5C6.32843 7 7 6.32843 7 5.5V1.5C7 0.671573 6.32843 0 5.5 0Z"
                      fill="#37BBC6" />
                    <path
                      opacity="0.3"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.5 9H5.5C6.32843 9 7 9.67157 7 10.5V14.5C7 15.3284 6.32843 16 5.5 16H1.5C0.671573 16 0 15.3284 0 14.5V10.5C0 9.67157 0.671573 9 1.5 9ZM10.5 0H14.5C15.3284 0 16 0.671573 16 1.5V5.5C16 6.32843 15.3284 7 14.5 7H10.5C9.67157 7 9 6.32843 9 5.5V1.5C9 0.671573 9.67157 0 10.5 0ZM10.5 9H14.5C15.3284 9 16 9.67157 16 10.5V14.5C16 15.3284 15.3284 16 14.5 16H10.5C9.67157 16 9 15.3284 9 14.5V10.5C9 9.67157 9.67157 9 10.5 9Z"
                      fill="#37BBC6" />
                  </svg>
                  {{ $t("investing-text-3") }}</a
                >
              </li>
              <li>
                <a class="anchor" href="#fuze"
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 6H3C3.55228 6 4 6.44772 4 7V9C4 9.55228 3.55228 10 3 10H1C0.447715 10 0 9.55228 0 9V7C0 6.44772 0.447715 6 1 6ZM7 0H9C9.55228 0 10 0.447715 10 1V3C10 3.55228 9.55228 4 9 4H7C6.44772 4 6 3.55228 6 3V1C6 0.447715 6.44772 0 7 0ZM7 6H9C9.55228 6 10 6.44772 10 7V9C10 9.55228 9.55228 10 9 10H7C6.44772 10 6 9.55228 6 9V7C6 6.44772 6.44772 6 7 6ZM13 0H15C15.5523 0 16 0.447715 16 1V3C16 3.55228 15.5523 4 15 4H13C12.4477 4 12 3.55228 12 3V1C12 0.447715 12.4477 0 13 0ZM13 6H15C15.5523 6 16 6.44772 16 7V9C16 9.55228 15.5523 10 15 10H13C12.4477 10 12 9.55228 12 9V7C12 6.44772 12.4477 6 13 6ZM1 12H3C3.55228 12 4 12.4477 4 13V15C4 15.5523 3.55228 16 3 16H1C0.447715 16 0 15.5523 0 15V13C0 12.4477 0.447715 12 1 12ZM7 12H9C9.55228 12 10 12.4477 10 13V15C10 15.5523 9.55228 16 9 16H7C6.44772 16 6 15.5523 6 15V13C6 12.4477 6.44772 12 7 12ZM13 12H15C15.5523 12 16 12.4477 16 13V15C16 15.5523 15.5523 16 15 16H13C12.4477 16 12 15.5523 12 15V13C12 12.4477 12.4477 12 13 12Z"
                      fill="#37BBC6" />
                  </svg>
                  {{ $t("investing-text-4") }}</a
                >
              </li>
            </ul>
          </div>
          <div class="how-aside-item">
            <ul class="reset-list how-aside-list">
              <li>
                <a class="anchor" href="#benefits">
                  {{ $t("investing-text-5") }}</a
                >
              </li>
              <li>
                <a class="anchor" href="#сommunity">
                  {{ $t("investing-text-6") }}</a
                >
              </li>
              <li>
                <a href="https://faq.coinfuze.com/" target="_blank">
                  {{ $t("investing-text-7") }}</a
                >
              </li>
              <li>
                <a href="https://docs.coinfuze.com/" target="_blank">
                  {{ $t("investing-text-8") }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </aside>
  </div>
</template>
<script>
export default {
  name: "InvestingNavMenu",
  data() {
    return {
      menuActive: true,
    };
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
