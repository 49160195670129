<template>
  <div class="sh sh-plus sh-line">
    <div class="sh-shadow" style="--shadow-color: #3baaac"></div>
    <div class="container">
      <div class="sh-header text-sm-center">
        <div class="sh-tag">
          <div class="sh-tag-text">
            <div class="sh-tag-shadow">{{ $t("about-text-1") }}</div>
          </div>
        </div>
        <h1 class="sh-title fw-700 mw-100">
          {{ $t("about-text-2") }}
          <span class="sh-title-gr">{{ $t("about-text-3") }}</span
          ><br />
          {{ $t("about-text-4") }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutBanner",
};
</script>
