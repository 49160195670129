<template>
  <main class="main">
    <status-banner />
    <div class="sh sh-dashed status">
      <div class="status-tag bt bt-gr bt-gr-ins">
        <span class="d-flex align-items-center"
          ><svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.16699 10L8.33366 14.1667L16.667 5.83334"
              stroke="url(#paint0_linear_1455_1908)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <defs>
              <linearGradient
                id="paint0_linear_1455_1908"
                x1="4.16699"
                y1="5.83334"
                x2="5.88052"
                y2="16.0713"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#503578" />
                <stop offset="1" stop-color="#3BAAAC" />
              </linearGradient>
            </defs>
          </svg>
          {{ $t("status-text-8") }}</span
        >
      </div>

      <div class="container">
        <div class="row gy-base">
          <status-progress-bar
            v-for="(val, key) in status"
            :label="key"
            :status="val"
            :key="key + val" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import StatusBanner from "@/components/StatusPage/StatusBanner.vue";
import StatusProgressBar from "@/components/StatusPage/StatusProgressBar.vue";
import SocketMixin from "@/mixins/SocketMixin.vue";
export default {
  name: "StatusPage",
  layout: "basic",
  components: {
    StatusBanner,
    StatusProgressBar,
  },
  mixins: [SocketMixin],
  computed: {
    ...mapGetters({
      status: "auth/getStatus",
    }),
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
