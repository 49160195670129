<template>
  <div class="sh-plus sh-line">
    <div class="sh investiong overflow-hidden">
      <div class="investiong-shadow center"></div>
      <div class="investiong-shadow left d-none d-sm-block"></div>
      <div class="container">
        <div
          class="investiong-inner d-flex align-items-center justify-content-between flex-column-reverse flex-md-row">
          <div class="investiong-content hero-content">
            <div class="sh-header">
              <div class="sh-tag">
                <div class="sh-tag-text">
                  <div class="sh-tag-shadow">{{ $t("investing-text-35") }}</div>
                </div>
              </div>
              <h1 class="sh-title">
                {{ $t("investing-text-36") }} <br />{{ $t("investing-text-37")
                }}<br />
                {{ $t("investing-text-38") }}
                <span>{{ $t("investing-text-39") }}</span>
              </h1>
              <p class="sh-desc">
                {{ $t("investing-text-40") }}
              </p>
            </div>
            <a
              class="bt bt-gr bt-gr-ins"
              href="https://ca.coinfuze.com/auth/register"
              target="_blank">
              <span>{{ $t("investing-text-41") }}</span></a
            >
          </div>
          <div class="investiong-img">
            <img src="@/assets/images/investiong-img.webp" alt="coinfuze" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestingBanner",
};
</script>
