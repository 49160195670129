<template>
  <div class="sh sh-plus sh-line single">
    <div class="sh-shadow" style="--shadow-color: #3baaac"></div>
    <div class="container">
      <div class="sh-header text-sm-center m-0">
        <div class="sh-tag">
          <div class="sh-tag-text">
            <div class="sh-tag-shadow">{{ $t("guide-text-1") }}</div>
          </div>
        </div>
        <h1 class="sh-title">{{ $t("guide-text-2") }}</h1>
        <p class="sh-desc">
          {{ $t("guide-text-3") }}
        </p>
        <a
          class="bt bt-gr bt-gr-ins"
          href="https://ca.coinfuze.com/auth/register"
          target="_blank">
          <span>{{ $t("guide-text-4") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: "GuideBanner" };
</script>
