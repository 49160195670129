<template>
  <main class="main">
    <home-hero />
    <home-about />
    <home-success />
    <home-security />
    <home-community />
  </main>
</template>

<script>
import { mapGetters } from "vuex";

import HomeHero from "@/components/HomePage/HomeHero.vue";
import HomeSuccess from "@/components/HomePage/HomeSuccess.vue";
import HomeAbout from "@/components/HomePage/HomeAbout.vue";
import HomeSecurity from "@/components/HomePage/HomeSecurity.vue";
import HomeCommunity from "@/components/HomePage/HomeCommunity.vue";

export default {
  name: "HomePage",
  layout: "basic",
  components: {
    HomeHero,
    HomeAbout,
    HomeSuccess,
    HomeSecurity,
    HomeCommunity,
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "CoinFuze",
      alternateName: "Coin Fuze LLC",
      url: "https://coinfuze.com/",
      logo: "https://coinfuze.com/img/logo.60d4afae.svg",
      sameAs: [
        "https://twitter.com/Coin_fuze",
        "https://discord.gg/B6MCMDR9qP",
      ],
    };
  },

  metaInfo() {
    return {
      title: this.$t("home-meta-title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("home-meta-description"),
        },
        {
          name: "og:title",
          content: this.$t("home-meta-title"),
        },
        {
          name: "og:description",
          content: this.$t("home-meta-description"),
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://coinfuze.com/${this.locale}/`,
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/en",
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/",
        },
        {
          rel: "alternative",
          hreflang: "ru",
          href: "https://coinfuze.com/ru",
        },
        {
          rel: "alternative",
          hreflang: "de",
          href: "https://coinfuze.com/de",
        },
        {
          rel: "alternative",
          hreflang: "es",
          href: "https://coinfuze.com/es",
        },
        {
          rel: "alternative",
          hreflang: "fa",
          href: "https://coinfuze.com/fa",
        },
        {
          rel: "alternative",
          hreflang: "it",
          href: "https://coinfuze.com/it",
        },
        {
          rel: "alternative",
          hreflang: "nl",
          href: "https://coinfuze.com/nl",
        },
        {
          rel: "alternative",
          hreflang: "tr",
          href: "https://coinfuze.com/tr",
        },
      ],
    };
  },
};
</script>
