<template>
  <div class="sh sh-plus sh-dashed about">
    <div class="container">
      <div class="row gy-base">
        <div class="col-lg-6">
          <div class="about-inner">
            <div class="about-header">
              <div class="sh-header">
                <div class="sh-tag">
                  <div class="sh-tag-text">
                    <div class="sh-tag-shadow">{{ $t("home-text-1") }}</div>
                  </div>
                </div>
                <h2 class="sh-title">
                  {{ $t("home-text-2") }}<br class="d-none d-sm-block" />{{
                    $t("home-text-3")
                  }}
                </h2>
              </div>
            </div>
            <div class="about-img text-center text-sm-end">
              <img src="@/assets/images/about-img.png" alt="coinfuze" />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content">
            <h4 class="about-content-title">
              {{ $t("home-text-4") }}
            </h4>
            <p class="about-content-desc">
              {{ $t("home-text-5") }}
            </p>
          </div>
          <a
            class="bt bt-gr bt-gr-ins"
            href="https://docs.coinfuze.com/docs/"
            target="_blank">
            <span>{{ $t("home-text-6") }}</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeAbout",
};
</script>
