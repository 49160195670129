<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2001_346)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4255 4.79913C21.6932 4.68645 21.9863 4.64759 22.2741 4.68659C22.5619 4.72559 22.834 4.84102 23.0621 5.02087C23.2902 5.20072 23.4659 5.43842 23.5709 5.70922C23.676 5.98002 23.7065 6.27402 23.6594 6.56063L21.2024 21.464C20.964 22.9016 19.3867 23.726 18.0683 23.01C16.9654 22.4109 15.3274 21.4879 13.8541 20.5248C13.1174 20.0427 10.8609 18.499 11.1382 17.4005C11.3765 16.4612 15.1682 12.9317 17.3349 10.8333C18.1853 10.0089 17.7974 9.5333 16.7932 10.2916C14.2994 12.1745 10.2954 15.0377 8.97152 15.8437C7.80369 16.5544 7.19485 16.6757 6.46686 16.5544C5.13869 16.3334 3.90694 15.991 2.90161 15.574C1.54311 15.0106 1.60919 13.143 2.90052 12.5991L21.4255 4.79913Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2001_346">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TelegramIcon'
}
</script>
