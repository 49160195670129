<template>
  <div class="sh sh-plus sh-dashed priorities">
    <div class="container">
      <div class="sh-header text-sm-center">
        <div class="sh-tag">
          <div class="sh-tag-text">
            <div class="sh-tag-shadow">{{ $t("about-text-25") }}</div>
          </div>
        </div>
        <h2 class="sh-title">{{ $t("about-text-26") }}</h2>
      </div>
      <div class="priorities-inner">
        <div class="row gy-base priorities-row">
          <div class="col-lg-4">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: #caa163"></div>
                <div class="success-box-icon">
                  <img src="@/assets/images/safety-icon-1.svg" alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">{{ $t("about-text-27") }}</h4>
                  <p class="success-box-desc">
                    {{ $t("about-text-28") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: #63a1fb"></div>
                <div class="success-box-icon">
                  <img src="@/assets/images/safety-icon-2.svg" alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">{{ $t("about-text-29") }}</h4>
                  <p class="success-box-desc">
                    {{ $t("about-text-30") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: #a060ff"></div>
                <div class="success-box-icon">
                  <img src="@/assets/images/safety-icon-3.svg" alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">{{ $t("about-text-31") }}</h4>
                  <p class="success-box-desc">
                    {{ $t("about-text-32") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutPriorities",
};
</script>
