<template>
  <!-- <div class="popup__wrapper" ref="popup_wrapper">
    <div class="popup" id="myModal">
      <div class="popup__header">
        <h3 class="popup__title" v-if="responseMessage">
          {{ responseMessage }}
        </h3>
        <span class="icon-close popup__icon" @click="closePopup"></span>
      </div>
      <div class="popup__content">
        <button @click="closePopup">Ок</button>
      </div>
    </div>
  </div> -->
  <div
    class="modal fade show"
    @click="closePopup"
    style="display: block !important"
    tabindex="-1"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-center">
        <button
          class="btn-close modal-close"
          type="button"
          data-bs-dismiss="modal"
          @click="closePopup"
          aria-label="Close">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.863757 0.861341C1.19357 0.531527 1.7283 0.531527 2.05812 0.861341L13.4046 12.2078C13.7344 12.5376 13.7344 13.0723 13.4046 13.4021C13.0747 13.732 12.54 13.7319 12.2102 13.4021L0.863757 2.0557C0.533944 1.72589 0.533943 1.19115 0.863757 0.861341Z"
              fill="#625B7A" />
            <path
              d="M0.862806 13.4022C0.532992 13.0723 0.532992 12.5376 0.862806 12.2078L12.2092 0.861356C12.5391 0.531542 13.0738 0.531541 13.4036 0.861355C13.7334 1.19117 13.7334 1.7259 13.4036 2.05572L2.05717 13.4021C1.72735 13.732 1.19262 13.732 0.862806 13.4022Z"
              fill="#625B7A" />
          </svg>
        </button>
        <div class="section-content-icon">
          <img src="@/assets/images/modal-icon.svg" alt="coinfuze" />
        </div>
        <div class="modal-content-title text-white">
          {{ $t("pop-up-title") }}
        </div>
        <button
          class="bt bt-gr modal-content-buttom"
          data-bs-dismiss="modal"
          @click="closePopup"
          aria-label="Close">
          <span>Ok</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupItem",
  data() {
    return {};
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
    },
  },
};
</script>
<style scoped lang="scss">
// .popup {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 999;
//   width: 100%;
//   height: 100%;
//   overflow-x: hidden;
//   overflow-y: auto;
//   outline: 0;
// }
</style>
