<template>
  <div class="sh sh-plus sh-line referral">
    <div class="container">
      <div class="row flex-lg-row-reverse align-items-center gy-base">
        <div class="col-lg-6">
          <div class="referral-img text-center">
            <img src="@/assets/images/referral-img.png" alt="coinfuze" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="referral-content">
            <div class="sh-header">
              <div class="sh-tag">
                <div class="sh-tag-text">
                  <div class="sh-tag-shadow">{{ $t("affiliate-text-10") }}</div>
                </div>
              </div>
              <h1 class="sh-title">{{ $t("affiliate-text-11") }}</h1>
              <p class="sh-desc">
                {{ $t("affiliate-text-12") }}
              </p>
            </div>
            <a
              class="bt bt-gr bt-gr-ins"
              href="https://ca.coinfuze.com/auth/register"
              target="_blank">
              <span>{{ $t("affiliate-text-13") }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AffiliateBanner",
};
</script>
