<template>
  <main class="main">
    <about-banner />
    <about-ecosystem />
    <about-priorities />
    <about-team />
    <about-story />
    <about-roadmap-native />
    <home-community />
  </main>
</template>

<script>
import { mapGetters } from "vuex";

import AboutBanner from "@/components/AboutPage/AboutBanner.vue";
import AboutEcosystem from "@/components/AboutPage/AboutEcosystem.vue";
import AboutPriorities from "@/components/AboutPage/AboutPriorities.vue";
import AboutTeam from "@/components/AboutPage/AboutTeam.vue";
import AboutStory from "@/components/AboutPage/AboutStory.vue";
import AboutRoadmapNative from "@/components/AboutPage/AboutRoadmapNative.vue";
import HomeCommunity from "@/components/HomePage/HomeCommunity.vue";

export default {
  name: "AboutPage",
  layout: "basic",
  components: {
    AboutRoadmapNative,
    AboutBanner,
    AboutTeam,
    AboutEcosystem,
    AboutPriorities,
    AboutStory,
    HomeCommunity,
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },
  mounted() {
    this.scrollToTop();

    //     const script = document.createElement("script");
    //     script.id = "roistat-script";
    //     script.innerHTML = `(function(w, d, s, h, id) {
    // w.roistatProjectId = id; w.roistatHost = h;
    // var p = d.location.protocol == "https:" ? "https://" : "http://";
    // var u = /^.roistat_visit=[^;]+(.)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    // var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
    // })(window, document, 'script', 'cloud.roistat.com', 'f5154a47715fbb5b35fb704cd376a28d');`;
    //     document.body.appendChild(script);
  },
  // beforeDestroy() {
  //   const existingScript = document.getElementById("roistat-script");
  //   if (existingScript) {
  //     existingScript.parentNode.removeChild(existingScript);
  //   }
  // },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://coinfuze.com/en/about-us",
      },
      headline:
        "History of the system, CoinFuze Team, Goals and RoadMap of the project.",
      description:
        "Learn more about the CoinFuze team, our founders and goals. We are committed to helping you achieve stable financial results with the CoinFuze automated system.",
      image: "https://coinfuze.com/img/about-img.810c56d0.webp",
      author: {
        "@type": "Organization",
        name: "CoinFuze",
        url: "https://coinfuze.com/",
      },
      publisher: {
        "@type": "Organization",
        name: "CoinFuze",
        logo: {
          "@type": "ImageObject",
          url: "https://coinfuze.com/img/logo.60d4afae.svg",
        },
      },
      datePublished: "2018-01-01",
      dateModified: "2024-04-19",
    };
  },
  metaInfo() {
    return {
      title: this.$t("about-meta-title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("about-meta-description"),
        },
        {
          name: "og:title",
          content: this.$t("about-meta-title"),
        },
        {
          name: "og:description",
          content: this.$t("about-meta-description"),
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://coinfuze.com/${this.locale}/about-us/`,
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/en/about-us",
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/about-us",
        },
        {
          rel: "alternative",
          hreflang: "ru",
          href: "https://coinfuze.com/ru/about-us",
        },
        {
          rel: "alternative",
          hreflang: "de",
          href: "https://coinfuze.com/de/about-us",
        },
        {
          rel: "alternative",
          hreflang: "es",
          href: "https://coinfuze.com/es/about-us",
        },
        {
          rel: "alternative",
          hreflang: "fa",
          href: "https://coinfuze.com/fa/about-us",
        },
        {
          rel: "alternative",
          hreflang: "it",
          href: "https://coinfuze.com/it/about-us",
        },
        {
          rel: "alternative",
          hreflang: "nl",
          href: "https://coinfuze.com/nl/about-us",
        },
        {
          rel: "alternative",
          hreflang: "tr",
          href: "https://coinfuze.com/tr/about-us",
        },
      ],
    };
  },
};
</script>
