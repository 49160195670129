<template>
  <main class="main">
    <investing-banner />
    <div class="sh sh-line how p-0">
      <div class="container">
        <div class="row how-row">
          <investing-nav-menu />
          <div class="col-lg-9">
            <div class="how-content">
              <investing-how-block />
              <investing-plans />
              <investing-fuze />
              <investing-benefits />
            </div>
          </div>
        </div>
      </div>
    </div>

    <home-community id="сommunity" />
  </main>
</template>

<script>
import { mapGetters } from "vuex";

import InvestingBanner from "@/components/InvestingPage/InvestingBanner.vue";
import InvestingNavMenu from "@/components/InvestingPage/InvestingNavMenu.vue";
import InvestingBenefits from "@/components/InvestingPage/InvestingBenefits.vue";
import InvestingFuze from "@/components/InvestingPage/InvestingFuze.vue";
import InvestingPlans from "@/components/InvestingPage/InvestingPlans.vue";
import InvestingHowBlock from "@/components/InvestingPage/InvestingHowBlock.vue";

import HomeCommunity from "@/components/HomePage/HomeCommunity.vue";

// const InvestingBanner = () =>
//   import("@/components/InvestingPage/InvestingBanner.vue");
// const InvestingNavMenu = () =>
//   import("@/components/InvestingPage/InvestingNavMenu.vue");
// const HomeCommunity = () => import("@/components/HomePage/HomeCommunity");
// const InvestingBenefits = () =>
//   import("@/components/InvestingPage/InvestingBenefits.vue");
// const InvestingFuze = () =>
//   import("@/components/InvestingPage/InvestingFuze.vue");
// const InvestingHowBlock = () =>
//   import("@/components/InvestingPage/InvestingHowBlock.vue");
// const InvestingPlans = () =>
//   import("@/components/InvestingPage/InvestingPlans.vue");
export default {
  name: "InvestingPage",
  layout: "basic",
  components: {
    HomeCommunity,
    InvestingBanner,
    InvestingNavMenu,
    InvestingBenefits,
    InvestingFuze,
    InvestingHowBlock,
    InvestingPlans,
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },
  mounted() {
    this.scrollToTop();

    //     const script = document.createElement("script");
    //     script.id = "roistat-script";
    //     script.innerHTML = `(function(w, d, s, h, id) {
    // w.roistatProjectId = id; w.roistatHost = h;
    // var p = d.location.protocol == "https:" ? "https://" : "http://";
    // var u = /^.roistat_visit=[^;]+(.)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    // var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
    // })(window, document, 'script', 'cloud.roistat.com', 'f5154a47715fbb5b35fb704cd376a28d');`;
    //     document.body.appendChild(script);
  },
  // beforeDestroy() {
  //   const existingScript = document.getElementById("roistat-script");
  //   if (existingScript) {
  //     existingScript.parentNode.removeChild(existingScript);
  //   }
  // },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://coinfuze.com/en/investing",
      },
      headline:
        "Investing in Cryptocurrencies with CoinFuze Professional Approach!",
      description:
        "Maximize your investing experience with CoinFuze, an innovative algorithmic ecosystem. Manage your portfolio easily, increase your returns with an automated system, and discover new horizons in cryptocurrency algorithms.",
      image: "https://coinfuze.com/img/investiong-img.f27d7351.webp",
      author: {
        "@type": "Organization",
        name: "CoinFuze",
        url: "https://coinfuze.com/",
      },
      publisher: {
        "@type": "Organization",
        name: "CoinFuze",
        logo: {
          "@type": "ImageObject",
          url: "https://coinfuze.com/img/logo.60d4afae.svg",
        },
      },
      datePublished: "2018-01-01",
      dateModified: "2024-04-19",
    };
  },
  metaInfo() {
    return {
      title: this.$t("investment-meta-title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("investment-meta-description"),
        },
        {
          name: "og:title",
          content: this.$t("investment-meta-title"),
        },
        {
          name: "og:description",
          content: this.$t("investment-meta-description"),
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://coinfuze.com/${this.locale}/investing/`,
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/en/investing",
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/investing",
        },
        {
          rel: "alternative",
          hreflang: "ru",
          href: "https://coinfuze.com/ru/investing",
        },
        {
          rel: "alternative",
          hreflang: "de",
          href: "https://coinfuze.com/de/investing",
        },
        {
          rel: "alternative",
          hreflang: "es",
          href: "https://coinfuze.com/es/investing",
        },
        {
          rel: "alternative",
          hreflang: "fa",
          href: "https://coinfuze.com/fa/investing",
        },
        {
          rel: "alternative",
          hreflang: "it",
          href: "https://coinfuze.com/it/investing",
        },
        {
          rel: "alternative",
          hreflang: "nl",
          href: "https://coinfuze.com/nl/investing",
        },
        {
          rel: "alternative",
          hreflang: "tr",
          href: "https://coinfuze.com/tr/investing",
        },
      ],
    };
  },
};
</script>
