<template>
  <div class="sh sh-plus sh-dashed priorities">
    <div
      class="sh-shadow d-none d-lg-block"
      style="--shadow-color: #3baaac"></div>
    <div class="container">
      <div class="sh-header text-sm-center">
        <div class="sh-tag">
          <div class="sh-tag-text">
            <div class="sh-tag-shadow">{{ $t("affiliate-text-1") }}</div>
          </div>
        </div>
        <h2 class="sh-title">{{ $t("affiliate-text-2") }}</h2>
        <p class="sh-desc">
          {{ $t("affiliate-text-3") }}
        </p>
      </div>
      <div class="priorities-inner">
        <div class="row gy-base priorities-row">
          <div class="col-lg-4">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: #caa163"></div>
                <div class="success-box-icon">
                  <img
                    src="@/assets/images/priorities-icon-1.svg"
                    alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">
                    {{ $t("affiliate-text-4") }}
                  </h4>
                  <p class="success-box-desc">
                    {{ $t("affiliate-text-5") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: #63a1fb"></div>
                <div class="success-box-icon">
                  <img
                    src="@/assets/images/priorities-icon-2.svg"
                    alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">
                    {{ $t("affiliate-text-6") }}
                  </h4>
                  <p class="success-box-desc">
                    {{ $t("affiliate-text-7") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="box success-box h-100">
              <div class="box-inner success-box-inner">
                <div
                  class="success-box-shadow left"
                  style="--shadow-color: #a060ff"></div>
                <div class="success-box-icon">
                  <img
                    src="@/assets/images/priorities-icon-3.svg"
                    alt="coinfuze" />
                </div>
                <div class="success-box-content">
                  <h4 class="success-box-title">
                    {{ $t("affiliate-text-8") }}
                  </h4>
                  <p class="success-box-desc">
                    {{ $t("affiliate-text-9") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default { name: "AffiliatePriorities" };
</script>
