import axios from "axios";

const state = {
  statistics: null,
};

const mutations = {
  SET_STATISTICS(state, stats) {
    state.statistics = stats;
  },
};
const actions = {
  getStatisticsData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("https://custxperts.com/api/p_statistics/")
        .then((response) => {
          commit("SET_STATISTICS", response.data.results);
          resolve(response.data.results);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const getters = {
  getStatistics: (state) => state.statistics,
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
