<template>
  <!-- <main> -->
  <!-- <AreaChart :labels="createdData" :data="profitData" /> -->
  <!-- <statistic-carousel /> -->
  <!-- <NegativeBarChart /> -->
  <!-- <History/> -->
  <!-- </main> -->
  <main class="main">
    <p
      v-if="loading"
      style="text-align: center; padding-top: 40px; height: 50vh">
      Loading....
    </p>
    <div v-else>
      <div class="sh sh-plus sh-line statistics">
        <div class="sh-shadow" style="--shadow-color: #3baaac"></div>
        <div class="container">
          <statistic-banner />
          <!-- <statistic-widgets :statisticsData="statisticsData" /> -->

          <div class="statistics-filter d-flex flex-wrap">
            <div class="statistics-filter-select flex-fill">
              <div
                class="cselect-wrapper"
                :class="{ active: periodMenu }"
                @click="togglePeriodMenu">
                <span class="cselect-label">
                  {{ getMonthYear(selectedPeriod.created) }}
                </span>
                <div class="cselect-arrow">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>
                <div
                  class="testwrapper"
                  v-if="periodMenu"
                  @click="closePeriodMenu"></div>
                <div class="cselect-list" v-if="periodMenu">
                  <span
                    class="cselect-item"
                    v-for="(period, idx) in statistics"
                    :key="`${period.created}${idx}`"
                    @click="selectPeriod(period, $event)">
                    {{ getMonthYear(period.created) }}
                  </span>
                </div>
              </div>
              <!-- <select
                class="input-control input-control-custom"
                v-model="selectedPeriod">
                <option
                  v-for="(period, idx) in statistics"
                  :key="`${period.created}${idx}`"
                  :value="period">
                  {{ getMonthYear(period.created) }}
                </option>
              </select> -->
            </div>
            <div class="statistics-filter-actions d-flex">
              <a
                class="bt bt-gr bt-gr-ins text-center"
                href="javascript:void(0);"
                @click="navigateToFileLink">
                <span>{{ $t("stat-text-3") }} CSV</span>
              </a>
              <a
                class="bt bt-gr bt-gr-ins text-center"
                href="https://coinfuze.com/docs/statistics.pdf"
                target="_blank">
                <span> {{ $t("stat-text-4") }} </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="sh sh-plus sh-dashed">
        <div class="container">
          <div class="row gy-base">
            <div class="col-lg-4">
              <div class="box panel h-100">
                <div class="box-inner p-0 panel-inner">
                  <div class="box-line">
                    <div class="panel-meta">
                      <div class="panel-meta-name">{{ $t("stat-text-5") }}</div>
                      <div
                        class="panel-meta-val text-white d-flex align-items-center">
                        {{ selectedPeriod.initial_balance }}
                        <div class="panel-meta-val-icon">
                          <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box-line">
                    <div class="panel-meta">
                      <div class="panel-meta-name">{{ $t("stat-text-6") }}</div>
                      <div
                        class="panel-meta-val text-white d-flex align-items-center">
                        {{ selectedPeriod.final_balance }}
                        <div class="panel-meta-val-icon">
                          <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="box panel h-100">
                <div class="box-inner p-0 panel-inner">
                  <div class="box-line">
                    <div class="panel-meta">
                      <div class="panel-meta-name">{{ $t("stat-text-7") }}</div>
                      <div
                        class="panel-meta-val text-white d-flex align-items-center">
                        {{ selectedPeriod.profit_by_month_usdt }}
                        <div class="panel-meta-val-icon">
                          <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box-line">
                    <div class="panel-meta">
                      <div class="panel-meta-name">
                        {{ $t("stat-text-7") }} %
                      </div>
                      <div
                        class="panel-meta-val text-white d-flex align-items-center">
                        {{ selectedPeriod.profit_by_month_percent }}%
                        <!-- <div class="panel-meta-val-icon">
                          <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="box panel h-100">
                <div class="box-inner p-0 panel-inner">
                  <div class="box-line">
                    <div class="row gy-base">
                      <div>
                        <div class="panel-meta">
                          <div class="panel-meta-name">
                            <span>{{ $t("stat-text-8") }}</span>
                          </div>
                          <div
                            class="panel-meta-val text-white d-flex align-items-center">
                            {{ selectedPeriod.trades_total }} /
                            {{ selectedPeriod.trades_profitable }}
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-6">
                        <div class="panel-meta">
                          <div class="panel-meta-name">
                            {{ $t("stat-text-9") }}
                          </div>
                          <div
                            class="panel-meta-val text-white d-flex align-items-center">
                            {{ selectedPeriod.trades_total }}
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="box-line">
                    <div class="panel-meta">
                      <div class="panel-meta-name">
                        {{ $t("stat-text-8") }} %
                      </div>
                      <div
                        class="panel-meta-val text-white d-flex align-items-center">
                        {{ selectedPeriod.trades_profitable_percent }}%
                        <!-- <div class="panel-meta-val-icon">
                          <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="box h-100">
                <div class="box-inner d-flex flex-column">
                  <div class="box-header">
                    <div class="box-title text-white">
                      {{ $t("stat-text-10") }}
                    </div>
                    <div class="box-desc">
                      {{ $t("stat-text-11") }}
                    </div>
                  </div>
                  <div class="box-content flex-fill">
                    <div class="chart" id="chart-1">
                      <apex-chart
                        height="100%"
                        width="100%"
                        type="area"
                        :options="chartIncomeOptions"
                        :series="seriesIncome" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="box h-100">
                <div class="box-inner">
                  <div class="box-header">
                    <div class="box-title text-white">
                      {{ $t("stat-text-12") }}
                    </div>
                    <div class="box-desc">
                      {{ $t("stat-text-13") }}
                    </div>
                  </div>
                  <div class="box-content">
                    <div class="chart" id="chart-2">
                      <apex-chart
                        height="100%"
                        width="100%"
                        type="area"
                        :options="chartMonthlyOptions"
                        :series="seriesMonthly" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
const VueApexCharts = () => import("vue-apexcharts");
import StatisticBanner from "@/components/StatisticPage/StatisticBanner";
import vClickOutside from "v-click-outside";
// import StatisticWidgets from "@/components/StatisticPage/StatisticWidgets"
// import AreaChart from "@/components/StatisticPage/Charts/AreaChart"
//import AreaChartNew from "@/components/StatisticPage/Charts/AreaChartNew.vue";
// import StatisticCarousel from "@/components/StatisticPage/StatisticCarousel.vue"
//import NegativeBarChart from "@/components/StatisticPage/Charts/NegativeBarChart";
//import History from "@/components/StatisticPage/History";
// import StatisticSelect from "@/components/StatisticPage/StatisticSelect/StatisticSelect.vue"
// import axios from "axios";
export default {
  name: "StatisticPage",
  layout: "basic",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      loading: false,
      stat: [],
      periodMenu: false,
      clickOutsideHandled: false,
      selectedPeriod: {
        created: "All Period",
        profit_by_month_usdt: 0,
        profit_by_month_percent: 0,
        initial_balance: 0,
        final_balance: 0,
        trades_total: 0,
        net_profits_data: [[new Date(), 0]],
        link: false,
        trades_profitable: 0,
        trades_profitable_percent: 0,
      },
    };
  },

  components: {
    // StatisticSelect,
    StatisticBanner,
    "apex-chart": VueApexCharts,
    // StatisticWidgets,
    // AreaChart,
    //AreaChartNew,
    // StatisticCarousel,
    //NegativeBarChart,
    //History,
  },
  //
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
    seriesMonthly() {
      let data = this.extractArr(this.selectedPeriod.net_profits_data, 1);
      // if (this.locale === "fa") {
      //   data = data.reverse();
      // }
      return [
        {
          name: "series1",
          data: data,
        },
      ];
    },

    chartMonthlyOptions() {
      return {
        chart: {
          height: "100%",
          width: "100%",
          type: "area",
          toolbar: false,
          fontFamily: "Onest, Arial, Helvetica, sans-serif",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        colors: ["#F86525"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.8,
            opacityFrom: 0.5,
            opacityTo: 0,
            // stops: [0, 80, 100],
          },
        },

        yaxis: {
          // opposite: this.locale === "fa",

          labels: {
            formatter: function (value) {
              const sign = value < 0 ? "-" : "";
              const absValue = Math.abs(value);

              if (absValue >= 1000000) {
                return sign + (absValue / 1000000).toFixed(0) + "M";
              } else if (absValue >= 1000) {
                return sign + (absValue / 1000).toFixed(0) + "K";
              } else {
                return sign + absValue.toFixed(0);
              }
            },
            style: {
              colors: "#ffffff",
              fontSize: "12px",
            },
            offsetX: -15,
          },
        },
        xaxis: {
          opposite: this.locale === "fa",
          categories: this.extractArr(this.selectedPeriod.net_profits_data, 0),
          tickAmount: 8,
          labels: {
            style: {
              colors: "#625B7A",
              fontSize: "12px",
            },
            formatter: function (value) {
              const date = new Date(value);

              if (this.selectedPeriod.created === "All Period") {
                return date.toLocaleDateString("default", {
                  month: "short",
                  year: "numeric",
                });
              } else {
                return value;
              }
            }.bind(this),
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          show: true,
          borderColor: "#181A1F",
          strokeDashArray: 7,
        },
        tooltip: {
          enabled: false,
        },
      };
    },

    seriesIncome() {
      let data = this.stat.map((item) => item.profit_comulative_usdt);
      // if (this.locale === "fa") {
      //   data = data.reverse();
      // }
      return [
        {
          name: "series1",
          data: data,
        },
      ];
    },
    chartIncomeOptions() {
      return {
        chart: {
          height: "100%",
          width: "100%",
          type: "area",
          toolbar: false,
          fontFamily: "Onest, Arial, Helvetica, sans-serif",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        colors: ["#51A6FE"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.8,
            opacityFrom: 0.5,
            opacityTo: 0,
            // stops: [0, 80, 100]
          },
        },
        yaxis: {
          // opposite: this.locale === "fa",

          labels: {
            formatter: function (value) {
              const sign = value < 0 ? "-" : "";
              const absValue = Math.abs(value);

              if (absValue >= 1000000) {
                return sign + (absValue / 1000000).toFixed(1) + "M";
              } else if (absValue >= 1000) {
                return sign + (absValue / 1000).toFixed(0) + "K";
              } else {
                return sign + absValue.toFixed(0);
              }
            },
            style: {
              colors: "#ffffff",
              fontSize: "12px",
            },
            offsetX: -15,
          },
        },
        xaxis: {
          opposite: this.locale === "fa",

          tickAmount: 8,
          categories: this.stat.map((item) => item.created),
          labels: {
            formatter: function (value) {
              const date = new Date(value);

              return date.toLocaleDateString("default", {
                month: "short",
                year: "numeric",
              });
            }.bind(this),
            style: {
              colors: "#625B7A",
              fontSize: "12px",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          show: true,
          borderColor: "#181A1F",
          strokeDashArray: 7,
        },
        tooltip: {
          enabled: false,
        },
      };
    },

    statistics() {
      return this.$store.getters["auth/getStatistics"];
    },
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://coinfuze.com/en/statistic",
      },
      headline: "CoinFuze: Detailed Algorithm Statistics for Investors",
      description:
        "See the performance statistics of the individual CoinFuze trading pool. Your path to optimized and intelligent investments starts here. Statistics are constantly updated.",
      image: "",
      author: {
        "@type": "Organization",
        name: "CoinFuze",
        url: "https://coinfuze.com/",
      },
      publisher: {
        "@type": "Organization",
        name: "CoinFuze",
        logo: {
          "@type": "ImageObject",
          url: "https://coinfuze.com/img/logo.60d4afae.svg",
        },
      },
      datePublished: "2018-01-01",
      dateModified: "2024-04-19",
    };
  },
  metaInfo() {
    return {
      title: this.$t("statistic-meta-title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("statistic-meta-description"),
        },
        {
          name: "og:title",
          content: this.$t("statistic-meta-title"),
        },
        {
          name: "og:description",
          content: this.$t("statistic-meta-description"),
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://coinfuze.com/${this.locale}/statistic/`,
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/en/statistic",
        },
        {
          rel: "alternative",
          hreflang: "en",
          href: "https://coinfuze.com/statistic",
        },
        {
          rel: "alternative",
          hreflang: "ru",
          href: "https://coinfuze.com/ru/statistic",
        },
        {
          rel: "alternative",
          hreflang: "de",
          href: "https://coinfuze.com/de/statistic",
        },
        {
          rel: "alternative",
          hreflang: "es",
          href: "https://coinfuze.com/es/statistic",
        },
        {
          rel: "alternative",
          hreflang: "fa",
          href: "https://coinfuze.com/fa/statistic",
        },
        {
          rel: "alternative",
          hreflang: "it",
          href: "https://coinfuze.com/it/statistic",
        },
        {
          rel: "alternative",
          hreflang: "nl",
          href: "https://coinfuze.com/nl/statistic",
        },
        {
          rel: "alternative",
          hreflang: "tr",
          href: "https://coinfuze.com/tr/statistic",
        },
      ],
    };
  },
  created() {
    this.getStatData();
  },
  mounted() {
    this.scrollToTop();

    //     const script = document.createElement("script");
    //     script.id = "roistat-script";
    //     script.innerHTML = `(function(w, d, s, h, id) {
    // w.roistatProjectId = id; w.roistatHost = h;
    // var p = d.location.protocol == "https:" ? "https://" : "http://";
    // var u = /^.roistat_visit=[^;]+(.)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    // var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
    // })(window, document, 'script', 'cloud.roistat.com', 'f5154a47715fbb5b35fb704cd376a28d');`;
    //     document.body.appendChild(script);
  },
  // beforeDestroy() {
  //   const existingScript = document.getElementById("roistat-script");
  //   if (existingScript) {
  //     existingScript.parentNode.removeChild(existingScript);
  //   }
  // },
  methods: {
    selectPeriod(period, event) {
      event.stopPropagation();
      this.selectedPeriod = period;
      this.periodMenu = false;
    },
    closePeriodMenu() {
      setTimeout(() => {
        this.periodMenu = false;
      }, 50);

      // this.clickOutsideHandled = true;
      // setTimeout(() => {
      //   this.clickOutsideHandled = false;
      // }, 500);
    },
    togglePeriodMenu() {
      // if (this.clickOutsideHandled) return;
      this.periodMenu = !this.periodMenu;
    },
    navigateToFileLink() {
      if (this.selectedPeriod.file_link.length) {
        window.location.href =
          "https://coinfuze.com/" + this.selectedPeriod.file_link;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    extractArr(data, idx) {
      return data.map((item) => item[idx]);
    },
    getStatData() {
      this.loading = true;
      this.$store
        .dispatch("auth/getStatisticsData")
        .then((res) => {
          this.stat = res.reverse();
          this.setDefaultOpt();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getMonthName(monthIndex) {
      // console.log(this.$t('monthNames.' + monthIndex));
      return this.$t("monthNames." + monthIndex);
    },
    getMonthYear(created) {
      if (created === "All Period") return this.$t("AllPeriod");
      const date = new Date(created);
      const month = String(date.getMonth());
      const year = date.getFullYear();
      return `${this.getMonthName(month)} ${year}`;
    },
    setDefaultOpt() {
      this.selectedPeriod = this.statistics[0];
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  direction: ltr !important;
}
.cselect-wrapper {
  min-height: 2.938rem;
  border-radius: 0.5rem;
  border: 1px solid #171d23;
  background: #10161c;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--s-white-color);
  padding: 1px 10px;
  &.active {
    .cselect-arrow {
      transform: rotate(180deg);
    }
    .cselect-list {
      display: flex;
      flex-direction: column;
      // gap: 7px;
    }
  }
}
.cselect-arrow {
  position: relative;
  display: flex;
  padding: 0.371rem;
  transition: transform ease-in-out 0.2s;
  -webkit-transition: transform ease-in-out 0.2s;
  transform: translateZ(0) rotate(0deg);
  will-change: transform;
  path {
    stroke: var(--s-white-color);
  }
}
.cselect-label {
  padding: 0.371rem;
}
.cselect-list {
  max-height: 155px;
  overflow-y: auto;
  position: absolute;
  top: 50px;
  left: -1px;
  display: none;
  border-radius: 0.5rem;
  border: 1px solid #171d23;
  background: #10161c;
  color: var(--s-white-color);
  padding: 10px 0px;
  min-width: 160px;
  width: auto;
  z-index: 5;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--s-black-color);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--s-white-color);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--s-grey-color);
  }
}
.cselect-item {
  padding: 10px 20px;
}
.cselect-item:hover {
  color: var(--s-blue-color);
}
.testwrapper {
  position: fixed;
  left: 0;
  top: 65px;
  z-index: 4;
  width: 100vw;
  height: 100vh;
}
</style>
